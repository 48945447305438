import Container from "../../../../components/atoms/container";
import Divisor from "../../../../components/atoms/divisor";
import Headerpictured from "../../../../components/atoms/headerpictured";
import Location from "../../../../components/organisms/location";

import AboutPicture from "../../../../assets/images/car_girls.png";

import AppButton from "../../../../components/atoms/app-button";
import BoltIcon from "../../../../components/atoms/icons/bolt";
import FaceIcon from "../../../../components/atoms/icons/face";
import GradingIcon from "../../../../components/atoms/icons/grading";
import AdvancedList from "../../../../components/molecules/advanced-list";
import "./styles.scss";

interface Props {}

const AboutPage: React.FC<Props> = () => {
  return (
    <section className="rent-about" style={{ marginTop: "-100px" }}>
      <Container>
        <Headerpictured
          pictureSource={AboutPicture}
          titleContent={
            <h2>
              Vire a chave na <br />
              sua cabeça e <br />
              <span>comece a alugar</span>
            </h2>
          }
        >
          <p>
            Sempre que precisar de um carro, de qualquer tipo, pode contar com a
            gente. Yalla Rent é nossa unidade de negócios de carros para atender
            sua necessidade onde você estiver.
          </p>
        </Headerpictured>
      </Container>
      <div className="bg-dark">
        <Container>
          <div className="why-area">
            <div>
              <h3>
                POR QUE ALUGAR <span>COM A GENTE?</span>
              </h3>
              <br />
              <p>Essa resposta é tão fácil que temos na ponta da língua.</p>
            </div>
            <div>
              <AdvancedList
                listItems={[
                  {
                    icon: <GradingIcon />,
                    text: "Impacto ambiental",
                  },
                  {
                    icon: <FaceIcon />,
                    text: "Impacto econômico",
                  },
                  {
                    icon: <BoltIcon />,
                    text: "Impacto nos seus negócios",
                  },
                ]}
              />
            </div>
          </div>
        </Container>
        <Divisor bgNumber="02" />
        <Container>
          <div className="contact">
            <h3>Contato</h3>
<br></br>
            <p>(11) 91848-9650</p>
            <p>E-mail: reservas@yallacar.com.br</p>
            <div className="content">
              <div>
                <p>Para você</p>
                <div className="buttons">
                  <AppButton
                    externalLink="https://rms188h.easycarros.com/Reserva/index.asp?COD_SISTEMA=1056"
                    theme="white"
                    label="Quero alugar"
                  />
                  <AppButton
                    theme="white"
                    label="Quero assinar"
                    externalLink="https://efleet2.erental.com.br/eTools/eRental/ReservaWebMensal/index.asp?COD_SISTEMA=1056"
                  />
                </div>
              </div>
              <div>
                <p>Para seu negócio</p>
                <div className="buttons">
                  <AppButton
                    theme="white"
                    label="Quero alugar"
                    externalLink="https://rms188h.easycarros.com/Reserva/index.asp?COD_SISTEMA=1056"
                  />
                  <AppButton
                    theme="white"
                    label="Quero assinar"
                    externalLink="https://efleet2.erental.com.br/eTools/eRental/ReservaWebMensal/index.asp?COD_SISTEMA=1056"
                  />
                </div>
              </div>
            </div>
          </div>
          <Location />
        </Container>
      </div>
    </section>
  );
};

export default AboutPage;
