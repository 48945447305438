import api from "./api-client";

export interface RegisterData {
  nome?: string;
  cpf?: string;
  rg?: string;
  sexo?: string;
  nacionalidade?: string;
  nascimento?: string;
  cep?: string;
  endereco?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  uf?: string;
  cidade?: string;
  email?: string;
  telefone?: string;
  veiculo?: string;
  contato?: string;
  residencia?: string;
  cnh?: string;
}

export default class ContactApi {
  static async send(data: RegisterData) {
    const formData = new FormData();

    Object.keys(data).forEach((f) => {
      //@ts-ignore
      formData.append(f, data[f]);
    });

    return api.post(
      `/contact-form-7/v1/contact-forms/568/feedback`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }
}
