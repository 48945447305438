import { IconProps } from ".";

const DirectionsCarIcon: React.FC<IconProps> = ({
  className,
  size = 40,
  color,
}) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_9587)">
        <path
          d="M31.5333 10.0163C31.2 9.03301 30.2667 8.33301 29.1667 8.33301H10.8333C9.73333 8.33301 8.81667 9.03301 8.46667 10.0163L5 19.9997V33.333C5 34.2497 5.75 34.9997 6.66667 34.9997H8.33333C9.25 34.9997 10 34.2497 10 33.333V31.6663H30V33.333C30 34.2497 30.75 34.9997 31.6667 34.9997H33.3333C34.25 34.9997 35 34.2497 35 33.333V19.9997L31.5333 10.0163ZM10.8333 26.6663C9.45 26.6663 8.33333 25.5497 8.33333 24.1663C8.33333 22.783 9.45 21.6663 10.8333 21.6663C12.2167 21.6663 13.3333 22.783 13.3333 24.1663C13.3333 25.5497 12.2167 26.6663 10.8333 26.6663ZM29.1667 26.6663C27.7833 26.6663 26.6667 25.5497 26.6667 24.1663C26.6667 22.783 27.7833 21.6663 29.1667 21.6663C30.55 21.6663 31.6667 22.783 31.6667 24.1663C31.6667 25.5497 30.55 26.6663 29.1667 26.6663ZM8.33333 18.333L10.8333 10.833H29.1667L31.6667 18.333H8.33333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_9587">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DirectionsCarIcon;
