import "./styles.scss";

import FacebookIcon from "../../../assets/images/facebookIcon.png";
import LinkedinIcon from "../../../assets/images/linkedinIcon.png";
import InstagramIcon from "../../../assets/images/instagramIcon.png";

import { useMemo } from "react";

export type SocialMediaProps = {
  className?: string;
  style?: any;
  photoSrcFacebook?: string;
  photoSrcLinkedin?: string;
  photoSrcInstagram?: string;
  hrefFacebook?: string;
  hrefLinkedin?: string;
  hrefInsgagran?: string;
  altFacebook?: string;
  altLinkdin?: string;
  altInstagram?: string;
};

const SocialMedia: React.FC<SocialMediaProps> = ({
  className,
  style,
  photoSrcFacebook,
  photoSrcLinkedin,
  photoSrcInstagram,
  hrefFacebook,
  hrefLinkedin,
  hrefInsgagran,
  altFacebook,
  altLinkdin,
  altInstagram,
}) => {
  const classNameValue = useMemo(() => {
    return ["social-media", className].join(" ");
  }, [className]);

  return (
    <div className={classNameValue} style={style}>
      {hrefFacebook ? (
        <a href={hrefFacebook} target="_blank" rel="noreferrer">
          <img src={photoSrcFacebook || FacebookIcon} alt={altFacebook} />
        </a>
      ) : (
        <></>
      )}
      {hrefLinkedin ? (
        <a href={hrefLinkedin} target="_blank" rel="noreferrer">
          <img src={photoSrcLinkedin || LinkedinIcon} alt={altLinkdin} />
        </a>
      ) : (
        <></>
      )}
      {hrefInsgagran ? (
        <a href={hrefInsgagran} target="_blank" rel="noreferrer">
          <img src={photoSrcInstagram || InstagramIcon} alt={altInstagram} />
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SocialMedia;
