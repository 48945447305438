import {
  useEffect,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import Theme from "../models/theme";
import PoliciesApi from "../services/policies";

type AppContextType = {
  theme: Theme;
  policies: any[];
  setTheme: (theme: Theme) => void;
  config: any;
};

const AppContext = createContext<AppContextType>({
  theme: Theme.main,
  policies: [],
  setTheme: (theme: Theme) => {},
  config: {},
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<Theme>(Theme.main);
  const [serverPolicies, setServerPolicies] = useState<any[]>([]);
  const [configs, setConfigs] = useState<any>({});

  useEffect(() => {
    async function loadPolices() {
      const { data } = await PoliciesApi.get();
      setServerPolicies(data);
      //@ts-ignore
      setConfigs(window.CONFIGS);
    }
    loadPolices();
  }, []);

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        policies: serverPolicies,
        config: configs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
