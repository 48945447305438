import { IconProps } from ".";

const YallaIcon: React.FC<IconProps> = ({ className, size = 28 }) => {
  const proportion = size / 28;
  const width = 40 * proportion;
  const height = 41 * proportion;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
    
    <g clipPath="url(#clip0_296_23977)">
        <path d="M18.4142 40.8425H19.862C19.862 40.8425 20.2244 40.6595 20.2244 39.8005C20.2244 38.9414 22.0347 24.542 22.0347 24.542L18.4142 26.3505V40.8425Z" />
        <path d="M6.31674 27.5463C6.04541 28.0673 5.01944 30.3043 5.01944 30.3043L5.16994 40.8434H6.64742L7.31091 31.2538L9.30137 26.627H6.58595C6.58595 26.627 6.58595 27.0253 6.31462 27.5463H6.31674Z" />
        <path d="M28 4.08861V0.712672C28 -0.0581095 27.3005 2.20691e-05 27.3005 2.20691e-05H17.4711C17.6004 2.77096 19.1691 2.3705 19.9004 1.82794C20.7674 1.18418 20.6041 2.24562 20.6041 2.24562L20.5045 15.73C20.5045 15.73 13.9502 7.62387 10.9444 4.20487C9.69581 2.78603 8.63381 3.36304 7.94913 4.33406C6.25119 6.73683 3.23688 11.3055 2.04134 13.2433C0.379438 15.9388 0 19.0198 0 19.0198V22.6347L1.4584 22.695V41.0022L3.1203 40.8838L3.93641 30.7516L6.3275 25.3303L14.2893 25.3906V40.8838H15.8347C15.8347 40.8838 17.2931 26.7233 17.876 25.1236C23.5633 23.4637 24.8458 18.221 24.7292 16.0293C24.6338 14.2229 23.9406 5.09838 23.9406 5.09838C23.9406 5.09838 23.9406 4.06062 24.7228 4.06062C25.4584 4.06062 25.982 5.09838 27.3535 5.09838C28.0233 5.09838 27.9958 4.09076 27.9958 4.09076L28 4.08861ZM26.6815 1.9442C26.5331 2.22409 26.3657 2.28007 26.2936 2.24132C26.2215 2.20256 26.1516 2.05616 26.3275 1.79564C26.5034 1.53728 26.6412 1.50283 26.7175 1.5502C26.7175 1.5502 26.8617 1.60187 26.6815 1.9442Z" />
      </g>
    </svg>
  );
};

export default YallaIcon;
