import "./styles.scss";

import { useMemo } from "react";
import AppButton from "../app-button";
import TitleSubtitle from "../title-subtitle";

export type KnowHowProps = {
  className?: string;
  style?: any;
  children: React.ReactNode;
  title: string;
  link?: string;
  labelButton: string;
};

const KnowHow: React.FC<KnowHowProps> = ({
  className,
  style,
  children,
  title,
  link,
  labelButton,
}) => {
  const classNameValue = useMemo(() => {
    return ["know-how", className].join(" ");
  }, [className]);

  return (
    <div className={classNameValue} style={style}>
      <TitleSubtitle title={title} children={children} />
      <AppButton link={link} label={labelButton} theme="white" />
    </div>
  );
};

export default KnowHow;
