import { useMemo } from "react";
import AdvancedListItem, {
  AdvancedListItemProps,
} from "../../atoms/advanced-list-item/AdvancedListItem";

import "./styles.scss";

export type AdvancedListProps = {
  className?: string;
  listItems: AdvancedListItemProps[];
};

const AdvancedList: React.FC<AdvancedListProps> = ({
  className,
  listItems,
}) => {
  const classNameValue = useMemo(() => {
    const classNames = ["advanced-list"];
    if (!!className) {
      classNames.push(className);
    }
    return classNames.join(" ");
  }, [className]);
  return (
    <ul className={classNameValue}>
      {listItems.map((listItem) => (
        <AdvancedListItem {...listItem} />
      ))}
    </ul>
  );
};

export default AdvancedList;
