import "./styles.scss";

import { useMemo } from "react";
import { Link } from "react-router-dom";
import SolutionCardPicture from "../../../assets/images/yalla_trust.png";

export type SolutionCardProps = {
  className?: string;
  style?: any;
  photoSrc?: string;
  photoSrcB?: string;
  children: React.ReactNode;
  title?: string;
  linkPath?: string;
  linked?: boolean;
  noDescription?: boolean;
  borderPosition?: "top-right" | "top-right" | "bottom-right" | "bottom-right";
  borderColor?: "indian-khaki" | "turquoise" | "punch" | "butter-cup";
};

const SolutionCard: React.FC<SolutionCardProps> = ({
  className,
  photoSrc,
  photoSrcB,
  children,
  linked,
  noDescription,
  linkPath = "#",
  title,
  style,
  borderPosition = "top-left",
  borderColor = "indian-khaki",
}) => {
  const classNameValue = useMemo(() => {
    const classNames = [
      "solution-card",
      className,
      `border-${borderPosition}`,
      `borderColor-${borderColor}`,
    ];
    if (linked) {
      classNames.push("link");
    }
    if (noDescription) {
      classNames.push("no-description");
    }
    return classNames.join(" ");
  }, [borderPosition, className, borderColor, linked, noDescription]);

  return linked ? (
    <Link
      to={linkPath}
      onClick={() => window.scrollTo(0, 0)}
      className={classNameValue}
      style={style}
    >
      <img src={photoSrc || SolutionCardPicture} alt={title} />
      <img
        className="photo-b"
        src={photoSrcB || SolutionCardPicture}
        alt={title}
      />
      <p>{children}</p>
      <span>Conheça</span>
    </Link>
  ) : (
    <div className={classNameValue} style={style}>
      <img src={photoSrc || SolutionCardPicture} alt={title} />
      {!noDescription && <p>{children}</p>}
    </div>
  );
};

export default SolutionCard;
