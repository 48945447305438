import Register from "../../../../components/templatesMy/registerMy";

import "./styles.scss";

interface Props {}

const RegisterPage: React.FC<Props> = () => {
  return (
    <div className="register-page">
       <br></br><br></br><br></br><br></br> <br></br><br></br>
      <Register businessType="my" />
    </div>
  );
};

export default RegisterPage;
