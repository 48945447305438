import React, { useMemo } from "react";
import SocialMedia from "../../atoms/social-media";
import "./styles.scss";
import AppButton from "../../atoms/app-button";

import FooterLogo from "../../../assets/images/yalla-logo-footer.png";
import Container from "../../atoms/container";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import WhatsappIcon from "../../../../assets/images/WhatsApp.png";

interface Props {
  className?: string;
}

const SiteFooter: React.FC<Props> = ({ className }) => {
  const { policies } = useAppContext();
  const classNameValue = useMemo(() => {
    const classValues = ["app-site-footer"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <section className={classNameValue}>
      <Container>
        <div className="header">
          <a href="#">
            <img src={FooterLogo} alt="" />
          </a>
          <SocialMedia
            hrefFacebook="https://www.facebook.com/Yalla.carros"
            hrefInsgagran="https://www.instagram.com/yalla_car"
            hrefLinkedin="https://www.linkedin.com/company/yalla-car"
          />
        </div>
        <div>
          <div>
            <p>
              <strong>
                Av. Washington Luís, 6675 - Congonhas
                <br />
                – Ed. Gate One
              </strong>{" "}
              <br />
              São Paulo - SP
              <br />
              CEP: 04627-004
              <br />
            </p>
          </div>
          <div>
            <p>
              <strong>Unidades</strong>
            </p>
            <ul className="solutions">
              <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="yalla/rent">
                  Yalla Rent
                </Link>
              </li>
              {<li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/yalla/trust">
                  Yalla Trust
                </Link>
              </li>}
              <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="yalla/my">
                  My yalla
                </Link>
              </li>
              <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="yalla/green">
                  Yalla Green
                </Link>
              </li>

            </ul>
          </div>
          <div className="inner_content">
            <ul>
              <li>
                <a href="mailto:yalla@yallacar.com.br">Trabalhe conosco</a>
              </li>
              <li>
                <a href="">Condições Gerais</a>
              </li>
              {policies.map((p: any) => (
                <li key={p.slug}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    to={`policy/${p.slug}`}
                  >
                    {p.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="contact">
            <ul className="contact-list">
              <li className="contact-item">
                <a href="">Central de vendas</a>
                <p>0800 080 0144</p>
                <AppButton
                  link="https://api.whatsapp.com/send?phone=5511918489650"
                  theme="astronaut"
                  label="Para reservas clique aqui" />
                   <small>24hrs todos os dias da semana</small>
              </li>
              <li className="contact-item">
                <a href="">Central de emergências</a>
                <p>0800 080 0072 / 11 91657-7281</p>
                
                <AppButton
                  link="https://api.whatsapp.com/send?phone=5511916577281"
                  theme="astronaut"
                  label="Para emergências clique aqui" />
                  <small>24hrs todos os dias da semana</small>
              </li>
            </ul>
          </div>
        </div>
        <div className="marks">
          <p>
            <small>Yalla - Todos os direitos reservados</small>
          </p>
          <p>
            <small>CNPJ: 33.011.265/0001-44</small>
          </p>
        </div>
      </Container>
    </section>
  );
};

export default SiteFooter;
