import "./styles.scss";

import WhatsappIcon from "../../../assets/images/whatsappIcon.png";

import { useMemo } from "react";

export type TalkToUsProps = {
  className?: string;
  style?: any;
  children: React.ReactNode;
  link?: string;
  title: string;
};

const TalkToUs: React.FC<TalkToUsProps> = ({
  className,
  style,
  children,
  link,
  title,
}) => {
  const classNameValue = useMemo(() => {
    return ["talk-to-us", className].join(" ");
  }, [className]);

  return (
    <div className={classNameValue} style={style}>
      <p>{children}</p>
      <a rel="noreferrer" href={link || ""} target="_blank">
        <img src={WhatsappIcon} alt={title} />
        <span>
          <h3>{title}</h3>
        </span>
      </a>
    </div>
  );
};

export default TalkToUs;
