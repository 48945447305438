import React, { InputHTMLAttributes, useMemo } from "react";
import InputMask from "react-input-mask";
import "./styles.scss";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  full?: boolean;
  label?: string;
  flex?: string;
  mask?: string;
  errorLabel?: string;
};

const Input: React.FC<Props> = ({
  className,
  full,
  label,
  value,
  mask,
  errorLabel,
  onChange,
  flex = "inherit",
  width,
  ...props
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-input"];
    if (className) classValues.push(className);
    if (full) classValues.push("full");
    if (errorLabel) classValues.push("error");
    return classValues.join(" ");
  }, [className, full, errorLabel]);

  return (
    <div
      className={classNameValue}
      style={{ flex, width: full ? "100%" : width }}
    >
      {label && <label>{label}</label>}
      {mask ? (
        <InputMask mask={mask} {...props} value={value} onChange={onChange}>
          {(inputProps: any) => (
            <input {...inputProps} type="tel" disableUnderline />
          )}
        </InputMask>
      ) : (
        <input {...props} value={value} onChange={onChange} />
      )}
      <small className="error">{errorLabel}</small>
    </div>
  );
};

export default Input;
