import AppButton from "../../../../components/atoms/app-button";
import Container from "../../../../components/atoms/container";

import Inlinefleet from "../../../../components/atoms/inlinefleet";

import "./styles.scss";

interface Props {
  item: any;
}

const FleetItem: React.FC<Props> = ({ item }) => {
  console.log(item);
  return item && Object.keys(item).length ? (
    <section className="fleet-item">
      <Container>
        <div className="car-data">
          <div className="car-photos">
            <picture>
              <img src={item.gallery[0].path} alt="" />
            </picture>
            <div>
              <a href="#">
                <img src={item.gallery[0].path} alt="" />
              </a>
              {item.gallery[1] && (
                <a href="#">
                  <img src={item.gallery[1].path} alt="" />
                </a>
              )}
              {item.gallery[2] && (
                <a href="#">
                  <img src={item.gallery[2].path} alt="" />
                </a>
              )}
            </div>
          </div>
          <div>
            <small>
              {item.city || "São Paulo"}, {item.state || "SP"}
            </small>
            <h3>{item.factory.name}</h3>
            <h3 className="model">{item.model.name}</h3>

            <div>
              <h3>
                <small>R$</small> {item.value || "--"}
              </h3>
              <br />
              <br />
            </div>
            <AppButton
              theme="punch"
              externalLink="https://api.whatsapp.com/send?phone=5511930576936"
              label="Falar com vendedor"
            />
          </div>
        </div>
        <h3>Sobre esse veículo</h3>
        <div className="about">
          <p>
            <small>Ano</small>
            <span>
              {item.year || "--"}/{item.factoryYear || "--"}
            </span>
          </p>
          <p>
            <small>Quilometragem</small>
            <span>{item.km || "--"}</span>
          </p>
          <p>
            <small>Transmissão</small>
            <span>{item.transmission.name}</span>
          </p>
          <p>
            <small>Cor</small>
            <span>{item.color || "--"}</span>
          </p>
          <p>
            <small>Combustível</small>
            <span>{item.fuel || "--"}</span>
          </p>
          <p>
            <small>Grupo</small>
            <span>{item.group.name}</span>
          </p>
        </div>
        <h3>Principais equipamentos</h3>
        <div className="equipments">
          {item.features.map((f: any) => (
            <span key={f.id}>{f.name}</span>
          ))}
        </div>
        <h3>Sobre esse veículo</h3>
        <p>{item.description || "--"}</p>
        <Inlinefleet title="Recomendados para você" businessType="my" />
      </Container>
    </section>
  ) : (
    <h1>Not found</h1>
  );
};

export default FleetItem;
