import React, { useMemo } from "react";
import "./styles.scss";

interface Props {
  children: React.ReactNode;
  leftSide: React.ReactNode;
  className?: string;
}

const Advantages: React.FC<Props> = ({ children, leftSide, className }) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-advantages"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <div className={classNameValue}>
      <div className="content">
        <div className="side">
          <h3>{leftSide}</h3>
        </div>

        <div className="txt-content">{children}</div>
      </div>
    </div>
  );
};

export default Advantages;
