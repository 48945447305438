import Register from "../../../../components/templates/register";

import "./styles.scss";

interface Props {}

const RegisterPage: React.FC<Props> = () => {
  return (
    <div className="register-page">
      <br></br><br></br><br></br><br></br>
       
      <Register businessType="rent" />
    </div>
  );
};

export default RegisterPage;
