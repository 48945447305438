import React, { useMemo, useState } from "react";
import Kwid from "../../../assets/images/kwid.png";
import Captur from "../../../assets/images/renault-captur.png";
import "./styles.scss";
import AppButton from "../../atoms/app-button";

import Line from "../../../assets/images/grafismos_05.svg";
interface Props {
  className?: string;
  externalLink?: string;
  icon?: string;
}

const BookCar: React.FC<Props> = ({ className, externalLink, icon }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const classNameValue = useMemo(() => {
    const classValues = ["app-book-car"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <div className={classNameValue} style={{ marginTop: "-180px" }}>
      <div className="panel">
        <div className="panel-tab">
          <a
            className={`${currentTab === 0 ? "active" : ""}`}
            onClick={() => setCurrentTab(0)}
          >
            Alugue
          </a>
      {   /* <a
            className={`${currentTab === 1 ? "active" : ""}`}
            onClick={() => setCurrentTab(1)}
          >
            Cadastre-se
  </a>*/}
        </div>
        <div className="panel-content">
          <img className="line" src={Line} alt="" />
          <div className={`tab assign ${currentTab === 0 ? "active" : ""}`}>
            <img src={Kwid} alt="" />
            <div className="">
              <h3>A melhor experiência <span>para o seu dia a dia</span></h3>
              <p>
                Seja para uma viagem ou para o seu dia a dia, nada é mais
                importante para a gente do que proporcionar uma experiência
                excelente de ponta a ponta.
              </p>
            </div>
            
          </div>
          <div className={`tab assign ${currentTab === 1 ? "active" : ""}`}>
            <img src={Captur} alt="" />
            <div>
              <h3>
                Pegue carona nessa ideia <span>Sempre um carro novo</span>
              </h3>
              <p>
                Optar por um carro alugado é um estilo de vida. Você diminui
                custos fixos, não perde com a desvalorização e pode ter em sua
                garagem sempre um carro novo.
              </p>
              {/* <AppButton
                fullSize
                theme="deep-sapphire"
                label="Solicitar uma proposta"
              /> */}
            </div>
            <AppButton
                            externalLink="https://rms188h.easycarros.com/Reserva/index.asp?COD_SISTEMA=1056"
                            icon={icon}
                            theme={"deep-sapphire"}
                            label="faça sua reserva aqui"
                          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookCar;
