import { useState } from "react";
import AppButton from "../../../../components/atoms/app-button";
import Container from "../../../../components/atoms/container";
import Register from "../../../../components/templatesMy/registerMy";

import "./styles.scss";

interface Props {}

const RegisterPage: React.FC<Props> = () => {
  const [interest, setInterest] = useState<string>("");
  return (
    <div className="register-page">
      {interest === "" ? (
        <div>
           <br></br><br></br><br></br>
          <Container>
            <div className="pre-form">
              <h3>Dados Pessoais</h3>
              <p>
                Mas antes, queremos entender como será o uso do seu Yalla Green.
              </p>
              <AppButton
                onClick={() => setInterest("Quero locar para mim")}
                theme="white"
                label="Quero locar para mim"
              />
              <AppButton
                onClick={() =>
                  setInterest("Quero locar para ser motorista de app")
                }
                theme="white"
                label="Quero locar para ser motorista de app"
              />
              <AppButton
                onClick={() => setInterest("Quero locar para minha empresa")}
                theme="white"
                label="Quero locar para minha empresa"
              />
            </div>
          </Container>
        </div>
      ) : (
        <Register businessType="green" interest={interest} />
      )}
    </div>
  );
};

export default RegisterPage;
