import "./styles.scss";

import { useMemo } from "react";

export type TitleSubtitleProps = {
  className?: string;
  style?: any;
  children: React.ReactNode;
  title?: string | React.ReactNode;
};

const TitleSubtitle: React.FC<TitleSubtitleProps> = ({
  className,
  style,
  children,
  title,
}) => {
  const classNameValue = useMemo(() => {
    return ["title-subtitle", className].join(" ");
  }, [className]);

  return (
    <div className={classNameValue} style={style}>
      {title && <h3>{title}</h3>}
      <span>{children}</span>
    </div>
  );
};

export default TitleSubtitle;
