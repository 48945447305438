import Container from "../../../../components/atoms/container";
import AppFleet from "../../../../components/organisms/app-fleet";
import WhatsappIcon from "../../../../assets/images/WhatsApp.png";

import "./styles.scss";

interface Props {}

const FleetPage: React.FC<Props> = () => {
  return (
    <section className="green-fleet" style={{ marginTop: "-90px" }}>
      <Container>
       
        <br />
        <br />
        <AppFleet
          externalLink="https://api.whatsapp.com/send?phone=555511918489650"
          icon={WhatsappIcon}
          businessType="green"
          title="Conheça a nossa frota híbrida"
          hasNoSideFilter
        />
      </Container>
    </section>
  );
};

export default FleetPage;
