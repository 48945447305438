import React, { useMemo } from "react";
import Container from "../../atoms/container";
import "./styles.scss";

interface Props {
  className?: string;
}

const Location: React.FC<Props> = ({ className }) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-location"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <Container className={classNameValue}>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.412138642765!2d-46.66426208458607!3d-23.62540698465114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a7abe1cd013%3A0x9cc7edb4fe6f7293!2sGate%20One!5e0!3m2!1spt-BR!2sbr!4v1673885529996!5m2!1spt-BR!2sbr"
          width="100%"
          height="367"
          loading="lazy"
        ></iframe>
      </div>
      <div className="data">
        <h3>Localização</h3>
        <p>
          Av. Washington Luís, 6675 - Congonhas
          <br />
          – Ed. Gate One <br />
          São Paulo - SP <br />
          CEP: 04627-004
        </p>
      </div>
    </Container>
  );
};

export default Location;
