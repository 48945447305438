import { IconProps } from ".";

const FilterIcon: React.FC<IconProps> = ({ className, size = 16, color }) => {
  const proportion = size / 16;
  const width = 16 * proportion;
  const height = 16 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.250178 1.61C2.27018 4.2 6.00018 9 6.00018 9V15C6.00018 15.55 6.45018 16 7.00018 16H9.00018C9.55018 16 10.0002 15.55 10.0002 15V9C10.0002 9 13.7202 4.2 15.7402 1.61C16.2502 0.95 15.7802 0 14.9502 0H1.04018C0.210178 0 -0.259822 0.95 0.250178 1.61Z"
        fill={color}
      />
    </svg>
  );
};

export default FilterIcon;
