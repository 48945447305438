import React, { InputHTMLAttributes, useMemo } from "react";
import AttachIcon from "../../../assets/images/attach_file.png";
import "./styles.scss";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  icon?: React.ReactNode;
  errorLabel?: string;
  fileTypes?: ("JPG" | "PNG" | "PDF")[];
  flex?: string;
};

const UploadField: React.FC<Props> = ({
  className,
  label,
  value,
  icon,
  fileTypes = ["JPG"],
  errorLabel,
  flex = "default",
  ...props
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-upload-field"];
    if (className) classValues.push(className);
    if (value) classValues.push("filled");
    if (errorLabel) classValues.push("error");
    return classValues.join(" ");
  }, [className, value, errorLabel]);

  return (
    <label className={classNameValue} style={{ flex }}>
      <span>
        <>
          <span>
            <img src={AttachIcon} alt="anexo" />
          </span>
          {icon && <span>{icon}</span>}
        </>
      </span>
      <small>{label}</small>
      {value && (
        <strong style={{ padding: "2px 0" }}>
          {value?.toString().split("\\").pop()}
        </strong>
      )}
      {errorLabel && (
        <small className="error" style={{ padding: "2px 0" }}>
          {errorLabel}
        </small>
      )}
      <small>Formatos aceitos: {fileTypes ? fileTypes.join(", ") : ""}</small>
      <input
        type="file"
        value={value}
        {...props}
        accept={fileTypes.join(",").toLowerCase()}
      />
    </label>
  );
};

export default UploadField;
