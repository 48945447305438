import { IconProps } from ".";

const FactCheckIcon: React.FC<IconProps> = ({
  className,
  size = 40,
  color,
}) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_10190)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.334 5H6.66732C4.83398 5 3.33398 6.5 3.33398 8.33333V31.6667C3.33398 33.5 4.83398 35 6.66732 35H33.334C35.1673 35 36.6673 33.5 36.6673 31.6667V8.33333C36.6673 6.5 35.1673 5 33.334 5ZM16.6673 28.3333H8.33398V25H16.6673V28.3333ZM16.6673 21.6667H8.33398V18.3333H16.6673V21.6667ZM16.6673 15H8.33398V11.6667H16.6673V15ZM24.7006 25L20.0007 20.2667L22.3507 17.9167L24.7006 20.2833L29.984 15L32.3507 17.3667L24.7006 25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_10190">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FactCheckIcon;
