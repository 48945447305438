import { Route, Routes } from "react-router-dom";
import About from "./inner-pages/about";
import Fleet from "./inner-pages/fleet";

import Overview from "./inner-pages/overview";
import Register from "./inner-pages/register";

import YallaGreenIcon from "../../assets/images/solutions/yalla_green.png";

import Container from "../../components/atoms/container";
import SolutionHeader from "../../components/molecules/solution-header";
import "./styles.scss";

const YallaGreenPage = () => {
  return (
    <section className="yalla-green-page" >
      <Container>
       
        <SolutionHeader
          iconPath={YallaGreenIcon}
          segmentPath="green"
          title="Yalla Green"
        />
         <iframe
          scrolling="no"
          style={{
            width: "1220px",
            maxWidth: "1920px",
            margin: "0 auto",
            height: 52,
            border: "0 none",
            background: "b#004A9E",
            overflow: "auto",
          }}
          src=""
        />
      </Container>

      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </section>
  );
};

export default YallaGreenPage;
