import { IconProps } from ".";

const VectorIcon: React.FC<IconProps> = ({ className, size = 10 }) => {
  const proportion = size / 10;
  const width = 10 * proportion;
  const height = 5 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 0L5 5L10 0H0Z" />
    </svg>
  );
};

export default VectorIcon;
