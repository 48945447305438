import Container from "../../../../components/atoms/container";
import Headerpictured from "../../../../components/atoms/headerpictured";
import Yallagroup from "../../../../components/atoms/yallagroup";

import OverviewPicture from "../../../../assets/images/my_jeep.png";
import Inlinefleet from "../../../../components/atoms/inlinefleet";

import Highlights from "../../../../components/molecules/highlights";
import "./styles.scss";

interface Props {}

const OverviewPage: React.FC<Props> = () => {
  return (
    <section className="my-overview">
      <Container>
        <Headerpictured
          pictureSource={OverviewPicture}
          link="/yalla/my/about"
          linkText="Saiba mais"
          bgNumber="08"
          titleContent={
            <h2>
              Aquele carro que <br />
              você sonha em ter <br />
              na garagem pode <br />
              ser seu
            </h2>
          }
        >
          <p>
            Esta é a nossa unidade de venda de seminovos. Quer levar o carro
            para casa e não precisar mais devolver ou pagar mensalidade? Então
            chega aqui.
          </p>
        </Headerpictured>
      </Container>
      <div className="bg-dark">
        <Container>
          <Inlinefleet
            title="Nossa frota"
            businessType="my"
            seeMoreLink="/yalla/my/fleet"
            link="/yalla/my/fleet"
          >
            Estes são os carros esperando por você.
          </Inlinefleet>
          <Highlights />
          <div className="why-area">
            <div>
              <h3>
                vantagens do <span>início ao fim</span>
              </h3>
              <br />
              <p>
                Para ser o lugar ideal pra você comprar seu seminovo, temos uma
                série de vantagens.
              </p>
            </div>

            <div>
              <h4>Experiência de venda</h4>
              <p>
                Você pode alugar o veículo por uma semana para teste. Se decidir
                efetuar a compra, esta semana teste fica isenta de taxa.
              </p>
              <p>
                Fale com um consultor por videochamada e faça um tour pelo
                veículo. Se quiser, também pode agendar uma visita.
              </p>
              <h4>Entrega rápida</h4>
              <p>
                Entregamos em todo o Brasil. Sim, fazemos delivery de veículos!
                Após o pagamento, a entrega em São Paulo é em 24 horas
                (rapidinho!). Para outros locais, pode falar com nosso
                consultor.
              </p>
              <h4>Variedade e agilidade</h4>
              <p>
                Enorme variedade de veículos. Para todos os gostos e bolsos, em
                ótimas condições com revisões completas e periódicas (fornecemos
                todo o histórico de manutenção). Além disso, você ganha
                agilidade no processo da compra.
              </p>
              <h4>Sua experiência é o mais importante</h4>
              <p>
                Desde que você decide comprar seu veículo seminovo com a gente
                até o momento em que recebe o carro, você encontra um time
                focado em proporcionar a melhor experiência.
              </p>
              <h4>Facilidade no pagamento</h4>
              <p>
                Pra não pesar no bolso, ajudamos você a encontrar o melhor
                financiamento. Você até pode usar seu carro como parte do
                pagamento.
              </p>
            </div>
          </div>
          <Yallagroup product="My Yalla" />
        </Container>
      </div>
    </section>
  );
};

export default OverviewPage;
