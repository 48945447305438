import React, { useMemo } from "react";
import AppButton from "../app-button";
import "./styles.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  titleContent?: React.ReactNode;
  link?: string;
  linkText?: string;
  pictureSource?: string;
  bgNumber?: "06" | "07" | "08";
  theme?:
    | "deep-sapphire"
    | "astronaut"
    | "indian-khaki"
    | "butter-cup"
    | "punch"
    | "turquoise"
    | "deep-grey"
    | "white";
}

const Headerpictured: React.FC<Props> = ({
  children,
  className,
  titleContent,
  link,
  linkText,
  pictureSource,
  bgNumber,
  theme = "deep-grey",
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-headerpictured"];
    if (className) classValues.push(className);
    if (bgNumber) classValues.push(`bg-${bgNumber}`);
    return classValues.join(" ");
  }, [className, bgNumber]);

  return (
    <div className={classNameValue}>
      <div>
        <div>{titleContent}</div>
        {children && <div>{children}</div>}
        {link && linkText && (
          <AppButton theme={theme} label={linkText} link={link} />
        )}
      </div>
      <picture>
        <img src={pictureSource} alt="" />
      </picture>
    </div>
  );
};

export default Headerpictured;
