import React from "react";
import "./styles.scss";

import { useMemo } from "react";
import { Link } from "react-router-dom";

export type OurFleetProps = {
    
  className?: string;
  style?: any;
  photoSrc?: string;
  title?: string;
  value?: number;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  alt?: string;
  link?: string;
  lastCard?: boolean;
  businessType?:string;
};

const OurFleet: React.FC<OurFleetProps> = ({
  className,
  photoSrc,
  title,
  value,
  children,
  alt,
  businessType,
  extra,
  style,
  link = "#",
  lastCard,
}) => {
  const classNameValue = useMemo(() => {
    return [
      "our-fleet",
      className,
      `our-fleet-${lastCard ? "true" : "false"}`,
    ].join(" ");
  }, [className, lastCard]);


  
  const formattedChildren = React.Children.map(children, (child) => {
    if (typeof child === "string") {
      return child.toUpperCase();
    }
    return child;
  });

  const formattedValue = useMemo(() => {
    const numberFormat = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
    return numberFormat.format(value || 0);
  }, [value]);

  return (
    <div className={classNameValue} style={style}>
      <picture>{photoSrc && <img src={photoSrc} alt={alt} />}</picture>
      <div>
        {lastCard ? (
          <Link onClick={() => window.scrollTo(0, 0)} to={link}>
            <h4>{title}</h4>
            <p></p> {/* Link "Veja todos os modelos" */}
          </Link>
        ) : link !== "#" ? (
          <Link onClick={() => window.scrollTo(0, 0)} to={link}>
            <h4>{title}</h4>
            
            {formattedChildren ? <p>{formattedChildren}</p> : <></>}
            <p> semana a partir de <br></br></p> {/* Na página que lista as frotas */}
          <p>{formattedValue}</p>
          </Link>
        ) : (
          <span>
          <h4>{title}</h4>
          {formattedChildren && <p>{formattedChildren}</p>}
          <p> semana a partir de </p> {/**/}
          
           <p>{formattedValue} </p>     
          </span>
        )}
      </div>
      {extra && extra}
    </div>
  );
};

export default OurFleet;
