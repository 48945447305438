import { IconProps } from ".";

const FavoriteIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_10199)">
        <path
          d="M20.0007 35.5833L17.584 33.3833C9.00065 25.6 3.33398 20.4667 3.33398 14.1667C3.33398 9.03333 7.36732 5 12.5007 5C15.4007 5 18.184 6.35 20.0007 8.48333C21.8173 6.35 24.6007 5 27.5007 5C32.634 5 36.6673 9.03333 36.6673 14.1667C36.6673 20.4667 31.0007 25.6 22.4173 33.4L20.0007 35.5833Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_10199">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FavoriteIcon;
