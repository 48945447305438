import { IconProps } from ".";

const FaceIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_10098)">
        <path
          d="M14.9987 19.584C13.8487 19.584 12.9154 20.5173 12.9154 21.6673C12.9154 22.8173 13.8487 23.7507 14.9987 23.7507C16.1487 23.7507 17.082 22.8173 17.082 21.6673C17.082 20.5173 16.1487 19.584 14.9987 19.584ZM24.9987 19.584C23.8487 19.584 22.9154 20.5173 22.9154 21.6673C22.9154 22.8173 23.8487 23.7507 24.9987 23.7507C26.1487 23.7507 27.082 22.8173 27.082 21.6673C27.082 20.5173 26.1487 19.584 24.9987 19.584ZM19.9987 3.33398C10.7987 3.33398 3.33203 10.8007 3.33203 20.0007C3.33203 29.2007 10.7987 36.6673 19.9987 36.6673C29.1987 36.6673 36.6654 29.2007 36.6654 20.0007C36.6654 10.8007 29.1987 3.33398 19.9987 3.33398ZM19.9987 33.334C12.6487 33.334 6.66536 27.3507 6.66536 20.0007C6.66536 19.5173 6.6987 19.034 6.7487 18.5673C10.682 16.8173 13.7987 13.6007 15.432 9.61732C18.4487 13.884 23.4154 16.6673 29.032 16.6673C30.332 16.6673 31.582 16.5173 32.782 16.234C33.132 17.4173 33.332 18.684 33.332 20.0007C33.332 27.3507 27.3487 33.334 19.9987 33.334Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_10098">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FaceIcon;
