import { IconProps } from ".";

const ChevronLeftIcon: React.FC<IconProps> = ({
  className,
  size = 26,
  color,
}) => {
  const proportion = size / 26;
  const width = 26 * proportion;
  const height = 24 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98356 12.0001L17.7168 19.293L16.2172 20.7072L6.98438 12.0001L16.2172 3.29297L17.7168 4.70718L9.98356 12.0001Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronLeftIcon;
