import { IconProps } from ".";

const ChevronRightIcon: React.FC<IconProps> = ({
  className,
  size = 26,
  color,
}) => {
  const proportion = size / 26;
  const width = 26 * proportion;
  const height = 24 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9168 12.0001L8.18359 4.70718L9.68319 3.29297L18.916 12.0001L9.68319 20.7072L8.18359 19.293L15.9168 12.0001Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronRightIcon;
