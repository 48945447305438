import Container from "../../components/atoms/container";

import "./styles.scss";

interface Props {}

const HomePage: React.FC<Props> = () => {
  return (
    <section className="not-found">
      <Container>
        <h2>404 - Not Found</h2>
      </Container>
    </section>
  );
};

export default HomePage;
