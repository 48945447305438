import { useEffect, useState } from "react";
import VehiclesApi from "../../../services/vehicles";
import AppFleet from "./AppFleet";

interface Props {
  businessType: "rent" | "green" | "my" | "trust";
  hasNoSideFilter?: boolean;
  link?: string;
  externalLink?: string;
  seeMoreLink?: string;
  title?: string;
  icon?: string;
  theme?:
    | "deep-sapphire"
    | "astronaut"
    | "indian-khaki"
    | "butter-cup"
    | "punch"
    | "turquoise"
    | "deep-grey"
    | "white";
}

const AppFleetContainer: React.FC<Props> = ({
  businessType,
  link,
  externalLink,
  seeMoreLink,
  theme,
  icon,
  title,
  hasNoSideFilter,
}) => {
  const [list, setList] = useState<any[]>([]);
  useEffect(() => {
    async function loadData() {
      const { data } = await VehiclesApi.get();

      setList(
        data.filter((d: any) => {
          if (!d.business) return false;
          return d.business.includes(businessType);
        })
      );
    }
    loadData();
  }, [setList, businessType]);
  return (
    <AppFleet
      link={link}
      externalLink={externalLink}
      icon={icon}
      title={title}
      theme={theme}
      seeMoreLink={seeMoreLink}
      list={list}
      twoButtons={businessType === "rent"}
      hasNoSideFilter={hasNoSideFilter}
    />
  );
};

export default AppFleetContainer;
