import Advantages from "../../../../components/atoms/advantages";
import Container from "../../../../components/atoms/container";
import Headerpictured from "../../../../components/atoms/headerpictured";
import Inlinefleet from "../../../../components/atoms/inlinefleet";
import Interestedin from "../../../../components/atoms/interestedin";
import Yallagroup from "../../../../components/atoms/yallagroup";

import PriusPicture from "../../../../assets/images/prius.png";

import "./styles.scss";

interface Props {}

const OverviewPage: React.FC<Props> = () => {
  return (
    <section className="green-overview">
      <Container>
        {/* <iframe
          scrolling="no"
          style={{
            width: "100%",
            height: 600,
            border: "0 none",
            overflow: "hidden",
          }}
          src="https://efleet2.erental.com.br/eTools/eRental/ReservaWebMensal/index.asp?COD_SISTEMA=1056"
        /> */}
        <Headerpictured
          titleContent={
            <>
              <h2>
                Carros elétricos e híbridos para cuidar do nosso principal
                veículo: o planeta.
              </h2>
            </>
          }
          bgNumber="07"
          link="/yalla/green/fleet"
          linkText="Assinar"
          pictureSource={PriusPicture}
          theme="turquoise"
        >
          <p className="first">
            <strong>Mobilidade + sustentabilidade</strong> <br />
            Todos estamos de carona no planeta Terra, por isso é importante
            cuidar bem dele. Foi pensando nisso que criamos a Yalla Green.
          </p>

          <p>
            Quando você aluga carros elétricos e híbridos, além de contribuir
            com a emissão de carbono na atmosfera, também economiza combustível.
          </p>
        </Headerpictured>
        <br />
        <br />
        <Inlinefleet
          title="Nossa frota"
          link="/yalla/green/fleet"
          seeMoreLink="/yalla/green/fleet"
          businessType="green"
          noFleetLink
        />
        <br />
        <br />
        <Advantages
          leftSide={
            <>
              VANTAGENS <span>PARA VOCÊ</span>, <br /> PARA NÓS E PARA <br /> O
              PLANETA
            </>
          }
        >
          <h4>ESG - Ambiental, social e governança</h4>
          <p>
            Esta sigla define três pilares de uma estratégia que tem sido
            adotada por muitas empresas. Esta estratégia demanda uma mudança de
            pensamento e cultura, mas traz muitos benefícios.
          </p>
          <h4>Economia financeira</h4>
          <p>
            Além de economizar combustível, você usa o capital que utilizaria na
            compra de um carro para investir. Quando você reduz os custos com
            abastecimento, melhora sua operação.
          </p>
          <h4>Desmobilização de frotas</h4>
          <p>
            Estratégia fundamental para garantir economia, eficiência e
            produtividade nas empresas.
          </p>
          <h4>Segurança e praticidade</h4>
          <p>
            Seguro com cobertura completa, manutenção feita por oficinas
            conveniadas em todo o Brasil, assistência 24 horas para pronto
            atendimento ao locatário e carro reserva da mesma categoria. E, pra
            facilitar sua vida, IPVA e documentação estão inclusos.
          </p>
          <h4>Para motoristas de aplicativos</h4>
          <p>
            Com carros de alto padrão, você pode se inserir em categorias
            superiores nos aplicativos e ganhar notas mais altas.
          </p>
        </Advantages>
        <Interestedin link="/yalla/green/register" />
        <Yallagroup product="Yalla Green" />
      </Container>
    </section>
  );
};

export default OverviewPage;
