import Container from "../../../../components/atoms/container";
import AppFleet from "../../../../components/organisms/app-fleet";

interface Props {}

const FleetPage: React.FC<Props> = () => {
  return (
    <section>
      <Container>
        <AppFleet
          externalLink="https://rms188h.easycarros.com/Reserva/index.asp?COD_SISTEMA=1056"
          businessType="rent"
        />
      </Container>
    </section>
  );
};

export default FleetPage;
