import OverviewPicture from "../../../../assets/images/trust_overview.png";
import AppButton from "../../../../components/atoms/app-button";
import Container from "../../../../components/atoms/container";
import Headerpictured from "../../../../components/atoms/headerpictured";
import Location from "../../../../components/organisms/location";

import "./styles.scss";

interface Props {}

const AboutPage: React.FC<Props> = () => {
  return (
    <section className="trust-about">
      <Container>
        {/* <div className="header">
          <div className="call">
            <h2>
              Existimos para <span>facilitar seu</span>
              <span> caminho</span>
            </h2>
          </div>
          <div>
            <Video thumbnailVideo={VideoThumb} />
          </div>
        </div> */}
        <Headerpictured
          bgNumber="06"
          titleContent={
            <>
              <h2>
                Existimos para <br /> <span>facilitar seu</span> <br />
                <span>caminho</span>
              </h2>
            </>
          }
          // link="#"
          linkText="Assinar"
          pictureSource={OverviewPicture}
          theme="turquoise"
        />
      </Container>
      <div className="bg-dark">
        <Container>
          <div className="know-better">
            <h3>Vamos nos conhecer melhor</h3>
            <p>Somos uma marca exclusiva para motoristas de aplicativos.</p>
            <p>
              Qualquer que seja o caminho que sua vida tenha tomado, sempre há
              espaço para recomeçar. O melhor ainda está por vir.
            </p>
            <p>
              Facilitamos crédito e damos prazo de pagamento para que você tenha
              tempo de se capitalizar e voltar a gerar renda. Bacana, né?
            </p>
            <p>
              E para garantir que nossos clientes estão comprometidos com a
              mudança, temos os indicadores de contrato. Eles são responsáveis
              por indicar e gerir novos contratos, podendo viabilizar uma renda
              extra e facilitar o acesso ao veículo a outros motoristas.{" "}
            </p>
            <p>
              Se você está precisando dessa ajuda ou acredita que pode ajudar
              outros como um indicador, fale com a gente. Vamos adorar te ouvir.
            </p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="our-contacts">
          <h3>
            Nossos <br /> contatos
          </h3>
          <br />
          <br />
          <div>
            <div>
              <h4>Fale com nossos consultores</h4>
              <br />
              <br />
              <AppButton
                link="https://api.whatsapp.com/send?phone=5511930576936"
                theme="white"
                label="Fale com o a gente"
              />
            </div>
            <div>
              <h4>Fale com nossos consultores</h4>
              <br />
              <br />
              <AppButton
                link="/yalla/trust/register"
                theme="white"
                label="Cadastre-se agora"
              />
            </div>
            <div>
              <h4>Fale com nossos consultores</h4>
              <br />
              <br />
              <AppButton
                link="https://api.whatsapp.com/send?phone=5511930576936"
                theme="white"
                label="Confira nossas vagas"
              />
            </div>
          </div>
        </div>
        <Location />
      </Container>
    </section>
  );
};

export default AboutPage;
