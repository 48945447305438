import { IconProps } from ".";

const AccessTimeIcon: React.FC<IconProps> = ({
  className,
  size = 40,
  color,
}) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_9922)">
        <path
          d="M19.984 3.33301C10.784 3.33301 3.33398 10.7997 3.33398 19.9997C3.33398 29.1997 10.784 36.6663 19.984 36.6663C29.2007 36.6663 36.6673 29.1997 36.6673 19.9997C36.6673 10.7997 29.2007 3.33301 19.984 3.33301ZM20.0007 33.333C12.634 33.333 6.66732 27.3663 6.66732 19.9997C6.66732 12.633 12.634 6.66634 20.0007 6.66634C27.3673 6.66634 33.334 12.633 33.334 19.9997C33.334 27.3663 27.3673 33.333 20.0007 33.333Z"
          fill={color}
        />
        <path
          d="M20.834 11.667H18.334V21.667L27.084 26.917L28.334 24.867L20.834 20.417V11.667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_9922">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccessTimeIcon;
