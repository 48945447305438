import "./styles.scss";
import ReactPlayer from "react-player";

import Thumbnail from "../../../assets/images/thumbnail.png";
import PlayButton from "../../../assets/images/playButton.png";

import { useMemo } from "react";

export type VideoProps = {
  className?: string;
  style?: any;
  thumbnailVideo?: string;
  srcVideo?: string;
  playButtonSrc?: string,
};

const Video: React.FC<VideoProps> = ({
  className,
  style,
  thumbnailVideo,
  srcVideo = "https://youtu.be/rQFhuE1_vCo",
  playButtonSrc,
}) => {
  const classNameValue = useMemo(() => {
    return ["video", className].join(" ");
  }, [className,]);

  return (
    <div className={classNameValue} style={style}>
      <ReactPlayer
        className="react-player"
        url={srcVideo}
        playing
        controls
        playIcon={<img src={playButtonSrc || PlayButton} alt="Play button" />}
        light={thumbnailVideo || Thumbnail}
      />
    </div>
  );
};

export default Video;
