import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";

import SearchIcon from "../../atoms/icons/search";
import MenuIcon from "../../../assets/images/MenuIcon.png";

interface Props {
  iconPath: string;
  title: string;
  segmentPath: string;
  className?: string;
}

const SolutionHeader: React.FC<Props> = ({
  className,
  iconPath,
  title,
  segmentPath,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const classNameValue = useMemo(() => {
    const classValues = ["app-solution-header"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <div className={classNameValue}>
      <Link to={`/yalla/${segmentPath}`} onClick={() => window.scrollTo(0, 0)}>
        <img src={iconPath} alt={title} />
      </Link>
      <button
        onClick={() => setMenuOpen((state) => !state)}
        className="inner-menu-button"
      >
        <img src={MenuIcon} alt="" />
      </button>
      <nav className={menuOpen ? "visible" : ""}>
        <li className="first">
          <a
            href="#"
            onClick={(e) => {
              window.scrollTo(0, 0);
              e.preventDefault();
              setMenuOpen(false);
            }}
          >
            X
          </a>
        </li>
        <li>
          <Link
            onClick={() => {
              setMenuOpen(false);
              window.scrollTo(0, 0);
            }}
            className={
              location.pathname === `/yalla/${segmentPath}` ? "active" : ""
            }
            to={`/yalla/${segmentPath}`}
          >
            Visão geral
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              setMenuOpen(false);
              window.scrollTo(0, 0);
            }}
            className={
              location.pathname === `/yalla/${segmentPath}/fleet`
                ? "active"
                : ""
            }
            to={`/yalla/${segmentPath}/fleet`}
          >
            Nossa frota
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              setMenuOpen(false);
              window.scrollTo(0, 0);
            }}
            className={
              location.pathname === `/yalla/${segmentPath}/about`
                ? "active"
                : ""
            }
            to={`/yalla/${segmentPath}/about`}
          >
            Sobre a {title}
          </Link>
        </li>
       
          <li>
            <Link
              onClick={() => {
                setMenuOpen(false);
                window.scrollTo(0, 0);
              }}
              className={
                location.pathname === `/yalla/${segmentPath}/register`
                  ? "active register"
                  : "register"
              }
              to={`/yalla/${segmentPath}/register`}
            >
              {segmentPath === "green" ? "Cadastre-se" : "Cadastre-se"}

            </Link>
          </li>
          {segmentPath === "my" && (
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() => setMenuOpen(false)}
              className={
                location.pathname === `/yalla/${segmentPath}/register`
                  ? "active register"
                  : "register"
              }
              href="https://api.whatsapp.com/send?phone=5511930576936"
            >
              Falar com um vendedor
            </a>
          </li>
        )}

        <li>
          <Link
            onClick={() => {
              setMenuOpen(false);
              window.scrollTo(0, 0);
            }}
            to={`/yalla/${segmentPath}/fleet`}
          >
            <SearchIcon />
          </Link>
        </li>
      </nav>
    </div>
  );
};

export default SolutionHeader;
