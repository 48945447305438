import React, { useMemo, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import AppButton from "../../atoms/app-button";
import FilterIcon from "../../atoms/icons/filter";
import OurFleet from "../../atoms/our-fleet";
import Pagination from "../../molecules/pagination";
import "./styles.scss";
interface Props {
  className?: string;
  link?: string;
  externalLink?: string;
  icon?: string;
  seeMoreLink?: string;
  title?: string;
  value?: number;
  twoButtons?: boolean;
  list: any[];
  theme?:
    | "deep-sapphire"
    | "astronaut"
    | "indian-khaki"
    | "butter-cup"
    | "punch"
    | "turquoise"
    | "deep-grey"
    | "white";
  hasNoSideFilter?: boolean;
}

const AppFleet: React.FC<Props> = ({
  className,
  list,
  icon,
  title,
  value,
  twoButtons,
  theme = "deep-sapphire",
  seeMoreLink,
  link,
  externalLink,
  hasNoSideFilter = false,
}) => {
  const { config } = useAppContext();

  const [filters, setFilters] = useState<{
    category: number;
    transmission: number;
    model: number;
    factory: number;
  }>({ category: 0, transmission: 0, model: 0, factory: 0 });

  const [page, setPage] = useState<number>(0);
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

  const filteredList = useMemo(() => {
    if (
      filters.category === 0 &&
      filters.transmission === 0 &&
      filters.factory === 0 &&
      filters.model === 0
    )
      return list;
    else {
      console.log(filters);
      return list
        .filter(
          (l) => filters.category === 0 || l.category.id === filters.category
        )
        .filter(
          (l) =>
            filters.transmission === 0 ||
            l.transmission.id === filters.transmission
        )
        .filter(
          (l) => filters.factory === 0 || l.factory.id === filters.factory
        )
        .filter((l) => filters.model === 0 || l.model.id === filters.model);
    }
  }, [list, filters]);
  const paginatedList = useMemo(() => {
    const startIndex = page * 30;
    return [...filteredList].slice(startIndex, startIndex + 30);
  }, [filteredList, page]);

  const classNameValue = useMemo(() => {
    const classValues = ["app-app-fleet"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  const handleSetFactory = (factory: number) => {
    setFilters({ ...filters, factory, model: 0 });
  };

  const handlePageChange = (index: number) => {
    setPage(index);
  };

  const CategoriesFilter = () => (
    <div className="filters">
      <h3>Categorias</h3>
      <ul>
        <li>
          <a
            onClick={() => {
              setFilters({ ...filters, category: 0 });
              setFilterOpen(false);
            }}
            className={filters.category === 0 ? "active" : ""}
          >
            Todas
          </a>
        </li>
        {config?.categoria?.map((m: any) => (
          <li key={m.id}>
            <a
              href="#"
              onClick={() => {
                setFilters({ ...filters, category: m.id });
                setFilterOpen(false);
              }}
              className={filters.category === m.id ? "active" : ""}
            >
              {m.name}
            </a>
          </li>
        ))}
      </ul>
      <h3>Transmissão</h3>
      <ul>
        <li>
          <a
            href="#"
            onClick={() => {
              setFilters({ ...filters, transmission: 0 });
              setFilterOpen(false);
            }}
            className={filters.transmission === 0 ? "active" : ""}
          >
            Todas
          </a>
        </li>
        {config?.transmissao?.map((m: any) => (
          <li key={m.id}>
            <a
              href="#"
              onClick={() => {
                setFilters({ ...filters, transmission: m.id });
                setFilterOpen(false);
              }}
              className={filters.transmission === m.id ? "active" : ""}
            >
              {m.name}
            </a>
          </li>
        ))}
      </ul>
     {/*  <h3>Marca</h3>
      <ul className={filters.factory === 0 ? "" : "selected"}>
        <li className="active">
          <a
            href="#"
            onClick={() => handleSetFactory(0)}
            className={filters.factory === 0 ? "active" : ""}
          >
            Todas
          </a>
        </li>
        {config?.marca?.map((m: any) => (
          <li key={m.id} className={filters.factory === m.id ? "active" : ""}>
            <a
              href="#"
              onClick={() => handleSetFactory(m.id)}
              className={filters.factory === m.id ? "active" : ""}
            >
              {m.name}
            </a>
            {m.subCategories && m.subCategories.length && (
              <ul>
                {m.subCategories.map((s: any) => (
                  <li key={s.id}>
                    <a
                      href="#"
                      onClick={() => {
                        setFilters({ ...filters, model: s.id });
                        setFilterOpen(false);
                      }}
                      className={filters.model === s.id ? "active" : ""}
                    >
                      {s.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul> */}
      <br />
      <a
        href="#"
        className="clearAll"
        onClick={() => {
          setFilterOpen(false);
          setFilters({
            category: 0,
            transmission: 0,
            model: 0,
            factory: 0,
          });
        }}
      >
        limpar tudo
      </a>
    </div>
  );

  return (
    <>
      <div className={classNameValue} style={{ marginTop: "-70px" }}>
        <h2>{title || "Conheça a nossa frota"}</h2>
        <div className="fleet-content">
          {!hasNoSideFilter && <CategoriesFilter />}
          <div className="result-content">
            <div className="content-data">
              <p>{filteredList.length} veículos encontrados</p>
              <div className="order">
                Ordenado por &nbsp;
                <select className="order-filter" name="" id="">
                  <option value="">Mais recentes</option>
                </select>
              </div>
              <div className="filter-button">
                <AppButton
                  rightIcon={<FilterIcon />}
                  onClick={() => setFilterOpen(true)}
                  label="Filtrar"
                  theme="white"
                />
              </div>
            </div>
            <div className="vehicles">
  {paginatedList.map((l) => (
    <OurFleet
      photoSrc={l.gallery && l.gallery.length > 0 ? l.gallery[0].pathMedium : ''}
      key={l.id}
      title={l.model.name}
      value={l.value}
      children={l.group ? l.group.name : ''}
                  extra={
                    <>
                      <div className="data">
                        {l.features.slice(0, 5).map((f: any) => (
                          <span key={f.id}>{f.name}</span>
                        ))}
                        {seeMoreLink && (
                          <a href={`${seeMoreLink}/${l.id}`}>Ver mais</a>
                        )}
                      </div>
                      {twoButtons ? (
                        <div className="two-buttons">
                          <AppButton
                            externalLink={externalLink}
                            icon={icon}
                            theme={"deep-sapphire"}
                            label="Alugar"
                          />
                          <AppButton
                            externalLink={externalLink}
                            icon={icon}
                            theme={"butter-cup"}
                            label="Assinar"
                          />
                        </div>
                      ) : (
                        <AppButton
                          link={link}
                          icon={icon}
                          theme={theme}
                          label="Falar com consultor"
                        />
                      )}
                    </>
                  }
                />
              ))}
            </div>
            {filteredList.length ? (
              <Pagination
                totalItems={filteredList.length}
                currentPage={page}
                onPageChange={handlePageChange}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={`floating-filter ${isFilterOpen ? "open" : ""}`}>
        <div className="content">
          <div className="header-f">
            <h4>Filtrar</h4>
            <a
              onClick={(e) => {
                e.preventDefault();
                setFilterOpen(false);
              }}
              href="#"
            >
              X
            </a>
          </div>
          <div className="content-f">
            <div>
              <label>Ordenação</label>
              <select name="" id="">
                <option value="">Mais recentes</option>
              </select>
            </div>
            <div>{!hasNoSideFilter && <CategoriesFilter />}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppFleet;
