import Container from "../../../../components/atoms/container";
import AppFleetTrust from "../../../../components/organisms/app-fleet-trust";
import WhatsappIcon from "../../../../assets/images/WhatsApp.png";

interface Props {}

const FleetPage: React.FC<Props> = () => {
  return (
    <section>
      <Container>
    
        <AppFleetTrust
          link="https://api.whatsapp.com/send?phone=555511918489650"
          icon={WhatsappIcon}
          businessType="trust"
          seeMoreLink="#"
        />
      </Container>
    </section>
  );
};

export default FleetPage;
