import Headerpictured from "../../../../components/atoms/headerpictured";
import Location from "../../../../components/organisms/location";
import AboutPicture from "../../../../assets/images/green_about.png";
import Container from "../../../../components/atoms/container";

import "./styles.scss";
import AppButton from "../../../../components/atoms/app-button";
import AdvancedList from "../../../../components/molecules/advanced-list";
import YallaIcon from "../../../../components/atoms/icons/YallaIcon";
import DirectionsCarIcon from "../../../../components/atoms/icons/directions-car";
import SecurityIcon from "../../../../components/atoms/icons/security";
import AccessTimeIcon from "../../../../components/atoms/icons/access-time";
import MoneyIcon from "../../../../components/atoms/icons/money";
import BuildIcon from "../../../../components/atoms/icons/build";
import Divisor from "../../../../components/atoms/divisor";

interface Props {}

const AboutPage: React.FC<Props> = () => {
  return (
    <section className="green-about" style={{ marginTop: "-100px" }}>
      <Container>
        <Headerpictured
          pictureSource={AboutPicture}
          titleContent={
            <h2>
              Nosso caminho <br />
              <span>pode ser</span> <br />
              <span>mais verde</span>
            </h2>
          }
        >
          <p>
            Oferecemos aluguel de carros elétricos e híbridos de alto padrão.
            Acreditamos que, ao cuidar do planeta, estamos construindo juntos um
            novo futuro.
          </p>

          <p>
            Alugar carros elétricos e híbridos é uma alternativa ambiental
            inteligente e economicamente viável.
          </p>
        </Headerpictured>
      </Container>
      <div className="bg-dark">
        <Container>
          <div className="know-better">
            <h3>sustentabilidade é importante</h3>
            <p>
              Todos somos passageiros neste planeta Terra. Cuidar dele é
              importante para que as próximas gerações tenham sua qualidade de
              vida garantida.
            </p>

            <p>
              Quando substituímos os carros tradicionais por elétricos ou
              híbridos, economizamos combustível, diminuindo a poluição e o
              gasto de energia não-renovável.
            </p>

            <p>
              Empresas que se preocupam com o meio ambiente são mais admiradas e
              conseguem criar relações mais profundas com seus clientes.
            </p>
          </div>
        </Container>
        <Divisor />
        <Container>
          <div className="why-use">
            <div className="side">
              <h3>
                Por que <span>Usar?</span>
              </h3>
            </div>
            <div>
              <AdvancedList
                listItems={[
                  {
                    icon: <YallaIcon />,
                    text: "Impacto ambiental",
                  },
                  {
                    icon: <DirectionsCarIcon />,
                    text: "Impacto econômico",
                  },
                  {
                    icon: <SecurityIcon />,
                    text: "Impacto nos seus negócios",
                  },
                ]}
              />
              <br />
              <h3>
                Vantagens <br />
                para empresas
              </h3>
              <br />
              <AdvancedList
                listItems={[
                  {
                    icon: <SecurityIcon />,
                    text: "Proteção",
                  },
                  {
                    icon: <AccessTimeIcon />,
                    text: "Assistência 24 horas",
                  },
                  {
                    icon: <DirectionsCarIcon />,
                    text: "Carro reserva",
                  },
                  {
                    icon: <BuildIcon />,
                    text: "Manutenção inclusa",
                  },
                  {
                    icon: <MoneyIcon />,
                    text: "Despesas deduzidas do imposto de renda",
                  },
                ]}
              />
            </div>
          </div>
          <div className="our-contacts">
            <h3>
              Quer saber <br /> como alugar?
            </h3>
            <br></br>
            <p>(11) 91848-9650</p>
            <p>E-mail: reservas@yallacar.com.br</p>
            <div>
              <div>
                <p>Quero assinar um carro para mim</p>

                <AppButton
                  link="https://api.whatsapp.com/send?phone=5511918489650"
                  theme="white"
                  label="Fale com o a gente"
                />
              </div>
              <div>
                <p>Quero assinar para ser motorista de app</p>
                <AppButton
                  link="/yalla/green/register"
                  theme="white"
                  label="Cadastre-se agora"
                />
              </div>
              <div>
                <p>Quero assinar para minha empresa</p>
                <AppButton
                  link="https://api.whatsapp.com/send?phone=5511918489650"
                  theme="white"
                  label="Fale com o a gente"
                />
              </div>
            </div>
          </div>
          <Location />
        </Container>
      </div>
    </section>
  );
};

export default AboutPage;
