import { Route, Routes } from "react-router-dom";
import About from "./inner-pages/about";
import Fleet from "./inner-pages/fleet";

import Overview from "./inner-pages/overview";
import Register from "./inner-pages/register";

import YallaTrustIcon from "../../assets/images/solutions/yalla_trust.png";

import Container from "../../components/atoms/container";
import SolutionHeader from "../../components/molecules/solution-header";
import "./styles.scss";

const YallaTrustPage = () => {
  return (
    <section className="yalla-trust-page">
      <Container>
        <SolutionHeader
          iconPath={YallaTrustIcon}
          segmentPath="trust"
          title="Yalla Trust"
        />
      </Container>

      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </section>
  );
};

export default YallaTrustPage;
