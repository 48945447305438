import { IconProps } from ".";

const BuildIcon: React.FC<IconProps> = ({ className, size = 32, color }) => {
  const proportion = size / 32;
  const width = 32 * proportion;
  const height = 32 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_9935)">
        <path
          d="M30.2659 25.3337L18.1326 13.2003C19.3326 10.1337 18.6659 6.53368 16.1326 4.00034C13.4659 1.33367 9.46593 0.800342 6.26593 2.26701L11.9993 8.00034L7.99927 12.0003L2.1326 6.26701C0.532601 9.46701 1.19927 13.467 3.86593 16.1337C6.39927 18.667 9.99927 19.3337 13.0659 18.1337L25.1993 30.267C25.7326 30.8003 26.5326 30.8003 27.0659 30.267L30.1326 27.2003C30.7993 26.667 30.7993 25.7337 30.2659 25.3337Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_9935">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BuildIcon;
