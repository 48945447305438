import React, { useMemo } from "react";
import "./styles.scss";

import YallaGroupPhoto from "../../../assets/images/yalla_group.jpg";

interface Props {
  className?: string;
  product?: string;
}

const Yallagroup: React.FC<Props> = ({
  className,
  product = "Yalla Trust",
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-yallagroup"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <div className={classNameValue}>
      <div className="call">
        <h3>Grupo yalla</h3>
        <p>
          {product} faz parte do Grupo Yalla, que tem o objetivo de tornar a
          mobilidade mais flexível, acessível e inclusiva.
        </p>

        <p>
          O Grupo Yalla é formado pela Yalla Trust, Yalla Green, Yalla Rent e My
          Yalla.
        </p>
      </div>
      <div>
        <img src={YallaGroupPhoto} alt="Grupo Yalla" />
      </div>
    </div>
  );
};

export default Yallagroup;
