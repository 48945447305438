import React, { useMemo } from "react";
import OurFleet from "../our-fleet";
import "./styles.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  seeMoreLink?: string;
  title?: string;
  value?: number;
  noFleetLink?: boolean;
  businessType?: string;
  list: any[];
}

const Inlinefleet: React.FC<Props> = ({
  children,
  className,
  noFleetLink,
  title,
  value,
  seeMoreLink,
  list,
  businessType,
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-inlinefleet"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <>
      {list.length > 0 && (
        <div className={classNameValue}>
          <div>
            <h3>{title}</h3>           
            {children}
            <h3>{value}</h3>
          </div>
          <div className="fleet-line">
  {list.slice(0, 3).map((l) => (
    <OurFleet
      photoSrc={l.gallery && l.gallery.length > 0 ? l.gallery[0].pathMedium : ''}
      key={l.id}
      title={l.model.name}
      value={l.value}
      link={!noFleetLink ? `/yalla/${businessType}/fleet/${l.id}` : undefined
                }
                children={l.group.name}
                extra={
                  businessType === "trust" && (
                    <>
                      <div className="data">
                        {l.features.slice(0, 5).map((f: any) => (
                          <span key={f.id}>{f.name}</span>
                        ))}
                        {seeMoreLink && (
                          <a href={`${seeMoreLink}/${l.id}`}>Ver mais</a>
                        )}
                      </div>
                    </>
                  )
                }
              />
            ))}
            <OurFleet
              link={seeMoreLink}
              title="Ver todos os modelos"
              lastCard
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Inlinefleet;
