import { Link, useLocation } from "react-router-dom";

import YallaTrustIcon from "../../assets/images/yalla_trust.png";

import { useMemo } from "react";
import Container from "../../components/atoms/container";
import { useAppContext } from "../../context/AppContext";
import "./styles.scss";

const App = () => {
  const { policies } = useAppContext();
  const location = useLocation();

  const policy = useMemo(() => {
    const slug = location.pathname.split("/").pop();
    const policy = policies.filter((p) => p.slug === slug);
    if (policy.length) return policy[0];
    return policies[0] ? policies[0] : {};
  }, [policies, location.pathname]);
  return (
    <section className="policy-page">
      <Container>
        <div className="nav">
          <Link to="/policy" onClick={() => window.scrollTo(0, 0)}>
            <img src={YallaTrustIcon} alt="Yalla trust" />
          </Link>
          <nav>
            {policies.map((p: any) => (
              <li>
                <Link
                  key={p.slug}
                  onClick={() => window.scrollTo(0, 0)}
                  to={`/policy/${p.slug}`}
                >
                  {p.title}
                </Link>
              </li>
            ))}
          </nav>
        </div>
        <div className="content">
          {policy.title ? (
            <>
              <h3>{policy.title}</h3>
              <br />
              <br />
              <div dangerouslySetInnerHTML={{ __html: policy.content }} />
            </>
          ) : (
            <p>carregando...</p>
          )}
        </div>
      </Container>
    </section>
  );
};

export default App;
