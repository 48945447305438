import "./styles.scss";

import { useMemo } from "react";
import TitleSubtitle from "../title-subtitle";

export type MakeUniqueProps = {
  className?: string;
  style?: any;
  photoSrc?: string;
  children: React.ReactNode;
  title?: string;
  side?: "left" | "right" | "top";
};

const MakeUnique: React.FC<MakeUniqueProps> = ({
  className,
  photoSrc,
  children,
  title,
  style,
  side = "right",
}) => {
  const classNameValue = useMemo(() => {
    return ["make-unique", className, `align-${side}`].join(" ");
  }, [className, side]);

  return (
    <div className={classNameValue} style={style}>
      <img src={photoSrc} alt={title} />
      <TitleSubtitle title={title} children={children} />
    </div>
  );
};

export default MakeUnique;
