import { IconProps } from ".";

const SecurityIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_9593)">
        <path
          d="M20 1.66699L5 8.33366V18.3337C5 27.5837 11.4 36.2337 20 38.3337C28.6 36.2337 35 27.5837 35 18.3337V8.33366L20 1.66699ZM20 19.9837H31.6667C30.7833 26.8503 26.2 32.967 20 34.8837V20.0003H8.33333V10.5003L20 5.31699V19.9837Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_9593">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SecurityIcon;
