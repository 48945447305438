import { IconProps } from ".";

const PlaceIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_9599)">
        <path
          d="M19.9987 3.33398C13.5487 3.33398 8.33203 8.55065 8.33203 15.0007C8.33203 23.7507 19.9987 36.6673 19.9987 36.6673C19.9987 36.6673 31.6654 23.7507 31.6654 15.0007C31.6654 8.55065 26.4487 3.33398 19.9987 3.33398ZM19.9987 19.1673C17.6987 19.1673 15.832 17.3007 15.832 15.0007C15.832 12.7007 17.6987 10.834 19.9987 10.834C22.2987 10.834 24.1654 12.7007 24.1654 15.0007C24.1654 17.3007 22.2987 19.1673 19.9987 19.1673Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_9599">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlaceIcon;
