import { IconProps } from ".";

const GradingIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}

      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_10090)">
        <path
          d="M6.66797 11.6667H33.3346V15H6.66797V11.6667ZM6.66797 21.6667H33.3346V18.3333H6.66797V21.6667ZM6.66797 28.3333H18.3346V25H6.66797V28.3333ZM6.66797 35H18.3346V31.6667H6.66797V35ZM25.6846 30.2833L23.3346 27.9167L20.9846 30.2667L25.6846 35L33.3346 27.3667L30.968 25L25.6846 30.2833ZM6.66797 5V8.33333H33.3346V5H6.66797Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_10090">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GradingIcon;
