import { Route, Routes } from "react-router-dom";
import About from "./inner-pages/about";
import Fleet from "./inner-pages/fleet";

import Overview from "./inner-pages/overview";
import Register from "./inner-pages/register";

import YallaRentIcon from "../../assets/images/solutions/yalla_rent.png";

import Container from "../../components/atoms/container";
import SolutionHeader from "../../components/molecules/solution-header";
import "./styles.scss";

const YallaRentPage = () => {
  return (
    <section className="yalla-rent-page">
      <Container>
        <SolutionHeader
          iconPath={YallaRentIcon}
          segmentPath="rent"
          title="Yalla Rent"
        />
 <div className="iframe-container-rent">
 <div className="select-overlay">
 
        <iframe
          scrolling="no"
          style={{
            width: "100%",
           // maxWidth: "1920px",
           //margin: "0 auto",
            height: 362,
            border: "0 none",
            background: "b#004A9E",
            overflow: "auto",
          }}
          src="https://reserva.easycarros.com/index.asp?COD_SISTEMA=1056"
        />
       {/*<img src={banner} alt="" style={{display: 'flex', objectFit: 'cover', zIndex: '-2'  }} />*/} 
         
        </div></div>
        <br></br><br></br><br></br><br></br>
        
        {/* <iframe
          scrolling="no"
          style={{
            width: "100%",
            maxWidth: "1250px",
            margin: "0 auto",
            height: 92,
            border: "0 none",
            background: "white",
            overflow: "hidden",
          }}
          src="https://efleet2.erental.com.br/eTools/eRental/reservaweb/index2.asp?cod_sistema=1056"
        />
        <iframe
          scrolling="no"
          style={{
            width: "100%",
            height: 150,
            border: "0 none",
            overflow: "hidden",
          }}
          src="https://efleet2.erental.com.br/eTools/eRental/ReservaWebMensal/index.asp?COD_SISTEMA=1056"
        /> */}
      </Container>

      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </section>
  );
};

export default YallaRentPage;
