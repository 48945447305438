import React, { useMemo } from "react";
import "./styles.scss";

import EconomyCar from "../../../assets/images/economicos.jpg";
import Hybrid from "../../../assets/images/hybrid.png";
import Spacer from "../../../assets/images/espacosos.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface Props {
  className?: string;
}

const Highlights: React.FC<Props> = ({ className }) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-highlights"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <>
      <div className={`${classNameValue} hidden-mobile`}>
        <div>
          <div className="card rt-border">
            <img src={EconomyCar} alt="" />
            <div>
              <h4>Econômicos</h4>
              <p>
                Para você que quer pouco consumo e alto rendimento. Com estes
                carros, você vai visitar cada vez menos o posto de gasolina (os
                frentistas vão até ficar com saudades). São diversas marcas e
                modelos para atender suas necessidades.
              </p>
            </div>
          </div>
          <div className="card lb-border">
            <div>
              <h4>Espaçosos</h4>
              <p>
                Para você que busca espaço, seja pra viajar, trabalhar ou levar
                toda a família. São os carros “coração de mãe”. Temos diversas
                marcas para entregar o que você precisa.
              </p>
            </div>
            <img style={{ marginBottom: "-5px" }} src={Spacer} alt="" />
          </div>
        </div>
        <div>
          <div className="card full">
            <img src={Hybrid} alt="" />
            <div>
              <h3>Híbridos ou eletrícos</h3>
              <p>
                Para você que quer economizar na gasolina e ainda ganhar uns
                pontos com o planeta. Encontre aqui o elétrico ou o híbrido
                ideal para você.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden-desktop">
        <br />
        <br />
        <Carousel showStatus={false} showThumbs={false}>
          <div
            className="card rt-border"
            style={{ borderTopRightRadius: "14%" }}
          >
            <img src={EconomyCar} alt="" />
            <div>
              <h4>Econômicos</h4>
              <p>
                Para você que quer pouco consumo e alto rendimento. Com estes
                carros, você vai visitar cada vez menos o posto de gasolina (os
                frentistas vão até ficar com saudades). São diversas marcas e
                modelos para atender suas necessidades.
              </p>
            </div>
          </div>
          <div
            className="card lb-border"
            style={{ borderTopRightRadius: "16%", borderTopLeftRadius: "16%" }}
          >
            <img style={{ marginBottom: "-5px" }} src={Spacer} alt="" />
            <div>
              <h4>Espaçosos</h4>
              <p>
                Para você que busca espaço, seja pra viajar, trabalhar ou levar
                toda a família. São os carros “coração de mãe”. Temos diversas
                marcas para entregar o que você precisa.
              </p>
            </div>
          </div>
          <div className="card full">
            <img src={Hybrid} alt="" />
            <div>
              <h4>Híbridos ou eletrícos</h4>
              <p>
                Para você que quer economizar na gasolina e ainda ganhar uns
                pontos com o planeta. Encontre aqui o elétrico ou o híbrido
                ideal para você.
              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default Highlights;
