import React, { CSSProperties, ReactNode } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

export type AppButtonProps = {
  label: string;
  fullSize?: boolean;
  link?: string;
  externalLink?: string;
  rightIcon?: ReactNode;
  icon?: string;
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
  onClick?: () => void;
  theme?:
    | "deep-sapphire"
    | "astronaut"
    | "indian-khaki"
    | "butter-cup"
    | "punch"
    | "turquoise"
    | "deep-grey"
    | "white";
};

const AppButton: React.FC<AppButtonProps> = ({
  label,
  fullSize,
  link,
  externalLink,
  style,
  type,
  onClick,
  rightIcon,
  icon,
  theme = "butter-cup",
}) => {
  const hdlClick = () => {
    if (onClick) {
      onClick();
    }
    window.scrollTo(0, 0);
  };
  return link ? (
    <Link
      to={link}
      style={style}
      onClick={hdlClick}
      className={`app-button ${theme} ${fullSize ? "full" : ""} ${
        icon || rightIcon ? "withIcon" : ""
      }`}
    >
      {icon && <img src={icon} alt="" />} {label}
      {rightIcon}
    </Link>
  ) : externalLink ? (
    <a
      target="_blank"
      rel="noreferrer"
      href={externalLink}
      style={style}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={`app-button ${theme} ${fullSize ? "full" : ""} ${
        icon || rightIcon ? "withIcon" : ""
      }`}
    >
      {icon && <img src={icon} alt="" />} {label}
      {rightIcon}
    </a>
  ) : (
    <button
      onClick={hdlClick}
      style={style}
      type={type}
      className={`app-button ${theme} ${fullSize ? "full" : ""} ${
        icon || rightIcon ? "withIcon" : ""
      }`}
    >
      {icon && <img src={icon} alt="" />} {label}
      {rightIcon}
    </button>
  );
};

export default AppButton;
