import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VehiclesApi from "../../../../services/vehicles";
import FleetItem from "./FleetItem";

interface Props {}

const AppFleetItemContainer: React.FC<Props> = () => {
  const { id } = useParams();
  const [item, setItem] = useState<any>({});
  useEffect(() => {
    async function loadData() {
      const { data } = await VehiclesApi.get();
      if (id) {
        const vechicles = data.filter((d: any) => {
          if (!d.business) return false;
          return d.business.includes("trust") && d.id === parseInt(id);
        });

        if (vechicles.length) setItem(vechicles[0]);
      }
    }
    loadData();
  }, [setItem, id]);
  return <FleetItem item={item} />;
};

export default AppFleetItemContainer;
