import { useState, useEffect } from "react";
import VehiclesApi from "../../../services/vehicles";
import Register from "./Register";

interface Props {
  businessType: "rent" | "green" | "my" | "trust";
  interest?: string;
}

const RegisterContainer: React.FC<Props> = ({ businessType, interest }) => {
  const [list, setList] = useState<any[]>([]);
  useEffect(() => {
    async function loadData() {
      const { data } = await VehiclesApi.get();

      setList(
        data.filter((d: any) => {
          if (!d.business) return false;
          return d.business.includes(businessType);
        })
      );
    }
    loadData();
  }, [setList, businessType]);
  return <Register interest={interest} list={list} />;
};

export default RegisterContainer;
