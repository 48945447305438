import AppButton from "../../../../components/atoms/app-button";
import Container from "../../../../components/atoms/container";
import Headerpictured from "../../../../components/atoms/headerpictured";
import AdvancedList from "../../../../components/molecules/advanced-list";

import AboutPicture from "../../../../assets/images/men_phone.png";

import Divisor from "../../../../components/atoms/divisor";
import FactCheckIcon from "../../../../components/atoms/icons/fact-check";
import FavoriteIcon from "../../../../components/atoms/icons/favorite";
import SecurityIcon from "../../../../components/atoms/icons/security";
import Location from "../../../../components/organisms/location";
import "./styles.scss";

interface Props {}

const AboutPage: React.FC<Props> = () => {
  return (
    <section className="my-about">
      <Container>
        <Headerpictured
          pictureSource={AboutPicture}
          titleContent={
            <h2>
              Venda de <br /> <span>seminovos é aqui!</span>
            </h2>
          }
        >
          <p>
            Temos uma enorme variedade de carros em ótimo estado e revisão em
            dia para você escolher.
          </p>
        </Headerpictured>
      </Container>
      <div className="bg-dark">
        <Container>
          <div className="why-area">
            <div>
              <h3>
                POR QUE comprAR <span>COM A GENTE?</span>
              </h3>
            </div>

            <div>
              <AdvancedList
                listItems={[
                  {
                    icon: <SecurityIcon />,
                    text: "Garantia dos veículos",
                  },
                  {
                    icon: <FactCheckIcon />,
                    text: "Procedência dos veículos",
                  },
                  {
                    icon: <FavoriteIcon />,
                    text: "Processo ágil e humanizado",
                  },
                ]}
              />
            </div>
          </div>
          <Divisor bgNumber="11" />
          <div className="our-contacts">
            <h3>Vamos começar?</h3>
            <div>
              <div>
                <p>Quero comprar um carro</p>
                <AppButton
                  externalLink="https://api.whatsapp.com/send?phone=555511918489650"
                  theme="white"
                  label="Compre agora"
                />
              </div>
              <div>
                <p>Quero agendar uma videochamada</p>
                <AppButton
                  externalLink="https://api.whatsapp.com/send?phone=555511918489650"
                  theme="white"
                  label="Agendar videochamada"
                />
              </div>
              <div>
                <p>Quero agendar uma visita</p>
                <AppButton
                  externalLink="https://api.whatsapp.com/send?phone=555511918489650"
                  theme="white"
                  label="Agendar visita"
                />
              </div>
            </div>
          </div>
          <Location />
        </Container>
      </div>
    </section>
  );
};

export default AboutPage;
