import { IconProps } from ".";

const MoneyIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_294_9941)">
        <path
          d="M19.6665 18.1667C15.8832 17.1833 14.6665 16.1667 14.6665 14.5833C14.6665 12.7667 16.3499 11.5 19.1665 11.5C22.1332 11.5 23.2332 12.9167 23.3332 15H27.0165C26.8999 12.1333 25.1499 9.5 21.6665 8.65V5H16.6665V8.6C13.4332 9.3 10.8332 11.4 10.8332 14.6167C10.8332 18.4667 14.0165 20.3833 18.6665 21.5C22.8332 22.5 23.6665 23.9667 23.6665 25.5167C23.6665 26.6667 22.8499 28.5 19.1665 28.5C15.7332 28.5 14.3832 26.9667 14.1999 25H10.5332C10.7332 28.65 13.4665 30.7 16.6665 31.3833V35H21.6665V31.4167C24.9165 30.8 27.4999 28.9167 27.4999 25.5C27.4999 20.7667 23.4499 19.15 19.6665 18.1667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_294_9941">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoneyIcon;
