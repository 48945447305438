import { useState, useEffect } from "react";
import VehiclesApi from "../../../services/vehicles";
import Inlinefleet from "./Inlinefleet";

interface Props {
  businessType: "rent" | "green" | "my" | "trust";
  children?: React.ReactNode;
  noFleetLink?: boolean;
  className?: string;
  title?: string;
  link?: string;
  seeMoreLink?: string;
}

const InlinefleetContainer: React.FC<Props> = ({
  businessType,
  seeMoreLink,
  noFleetLink,
  link,
  ...props
}) => {
  const [list, setList] = useState<any[]>([]);
  useEffect(() => {
    async function loadData() {
      const { data } = await VehiclesApi.get();

      setList(
        data.filter((d: any) => {
          if (!d.business) return false;

          return d.business.includes(businessType);
        })
      );
    }
    loadData();
  }, [setList, businessType]);
  return (
    <Inlinefleet
      businessType={businessType}
      seeMoreLink={seeMoreLink}
      list={list}
      noFleetLink={noFleetLink}
      {...props}
    />
  );
};

export default InlinefleetContainer;
