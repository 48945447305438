import CreditCard from "../../../../assets/images/credit_card.png";
import Container from "../../../../components/atoms/container";

import OverviewPicture from "../../../../assets/images/trust_overview.png";
import Advantages from "../../../../components/atoms/advantages";
import Headerpictured from "../../../../components/atoms/headerpictured";
import Interestedin from "../../../../components/atoms/interestedin";
import KnowHow from "../../../../components/atoms/know-how";
import Yallagroup from "../../../../components/atoms/yallagroup";
import Highlights from "../../../../components/molecules/highlights";
import "./styles.scss";

interface Props {}

const OverviewPage: React.FC<Props> = () => {
  return (
    <section className="trust-overview">
      <Container>
        <Headerpictured
          pictureSource={OverviewPicture}
          link="/yalla/trust/register"
          theme="indian-khaki"
          linkText="Faça seu cadastro"
          bgNumber="06"
          titleContent={<h2>A estrada é mais fácil quando vamos juntos</h2>}
        >
          <p>
            Todo mundo merece ter condições de prosperar. É por isso que a Yalla
            Trust é a melhor opção para você alugar seu carro de aplicativo.
          </p>

          <p>
            Analisamos caso a caso para oferecer as melhores condições, de um
            jeito que fique bom para todos.
          </p>
        </Headerpictured>
      </Container>
      <div className="bg-dark">
        <Container>
          <div className="know">
            <KnowHow
              title="Uma frota diversificada"
              labelButton="Ver todos os modelos"
              link="/yalla/trust/fleet"
            >
              Conheça a frota de veículos que temos disponível. São diversos
              modelos de carro para você alugar e oferecer o melhor para seus
              passageiros.
            </KnowHow>
          </div>
          <Highlights />
        </Container>

        <Container>
          <Interestedin
            link="/yalla/trust/register"
            linkText="Faça seu cadastro"
          />
          <div className="no-card">
            <span>
              <img src={CreditCard} alt="Sem cartão" />
            </span>
            <p>Você não precisa de cartão de crédito para alugar.</p>
          </div>
          <Advantages
            leftSide={
              <>
                {" "}
                muitas, muitas<span> vantagens</span>
              </>
            }
          >
            <h4>Crédito humano</h4>
            <p>
              Pode contar com a gente! Com nossa análise de crédito
              personalizada, você pode fazer planos semanais sem burocracias. 
              Legal, né?
            </p>
            <h4>Carros híbridos e elétricos</h4>
            <p>
              Já pensou em oferecer carros diferenciados para seus passageiros e
              entrar em categorias superiores nos apps de transporte?
            </p>
            <h4>Pagamento facilitado</h4>
            <p>
              Você não precisa de cartão de crédito. Temos planos semanais são
              pós-pagos e com pagamento facilitado. Você tem 7 dias para começar
              a pagar.
            </p>
            <h4>Plano completo</h4>
            <p>
              Inclui manutenção e seguro para você não precisar se preocupar com
              nada.
            </p>
          </Advantages>
          <Yallagroup />
        </Container>
      </div>
    </section>
  );
};

export default OverviewPage;
