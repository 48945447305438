import React, { useMemo } from "react";
import MakeUnique from "../../atoms/make-unique";
import "./styles.scss";
import { MakeUniqueProps } from "../../atoms/make-unique/MakeUnique";

import TouchPicture from "../../../assets/images/touch.png";
import DrivePicture from "../../../assets/images/drive.png";
import FriendsPicture from "../../../assets/images/friends.png";
import manPicture from "../../../assets/images/man.png";
import streetPicture from "../../../assets/images/street.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface Props {
  title: React.ReactNode | string;
  className?: string;
}

const data: MakeUniqueProps[] = [
  {
    title: "Sempre em frente!",
    photoSrc: streetPicture,
    children:
      "Nosso posicionamento guia todas as nossas ações. Sempre em frente representa não só o ato do movimento, mas também a constante evolução dos serviços e da experiência que oferecemos.",
  },
  {
    title: "Flexibilidade",
    photoSrc: TouchPicture,
    children:
      "Procuramos sempre construir a melhor alternativa de acordo com a necessidade apresentada. Trabalhamos na base da confiança. ",
  },
  {
    photoSrc: FriendsPicture,
    title: "Atendimento personalizado",
    children:
      "No fim, tudo se resume a gente atendendo gente. Para nós, é importante criar vínculo e estabelecer confiança.",
  },
  {
    photoSrc: manPicture,
    title: "Crédito humano",
    children:
      "Fazemos uma análise baseada no potencial futuro de cada pessoa e não na atual circunstância em que ela se encontra.",
  },
  {
    photoSrc: DrivePicture,
    title: "É isso que nos move",
    children:
      "Vamos tornar a mobilidade mais flexível, acessível, inclusiva. É por isso que seguimos sempre em frente.",
  },
];

const MakeusUnique: React.FC<Props> = ({ title, className }) => {
  const classNameValue = useMemo(() => {
    const classValues = [];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <>
      <div className={`${classNameValue} app-makeus-unique`}>
        <div>
          <h2>{title}</h2>
          {data.map((d, index) => (
            <MakeUnique
              key={d.title}
              {...d}
              side={index % 2 === 0 ? "left" : "right"}
            />
          ))}
        </div>
      </div>
      <div className={`${classNameValue} app-makeus-unique-mobile`}>
        <div>
          <h2>{title}</h2>
          <Carousel showStatus={false} autoPlay>
            {data.map((d) => (
              <MakeUnique key={d.title} {...d} side="top" />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default MakeusUnique;
