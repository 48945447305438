import React, { useMemo } from "react";
import ChevronLeftIcon from "../../atoms/icons/chevron-left";
import ChevronRightIcon from "../../atoms/icons/chevron-right";
import "./styles.scss";

interface Props {
  className?: string;
  totalItems: number;
  currentPage: number;
  onPageChange: (index: number) => void;
}

const Pagination: React.FC<Props> = ({
  className,
  totalItems,
  currentPage,
  onPageChange,
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-pagination"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  const pagesList = useMemo(() => {
    return Array.from(Array(Math.round(totalItems / 30)).keys());
  }, [totalItems]);

  const hdlPageChange = (e: any, index: any) => {
    e.preventDefault();
    onPageChange(index);
    window.scrollTo(0, 0);
  };

  return (
    <div className={classNameValue}>
      {pagesList.length > 1 && (
        <ul>
          <li className="arrow">
            <a
              href="#"
              className="arrow"
              onClick={(e) => hdlPageChange(e, Math.max(0, currentPage - 1))}
            >
              <ChevronLeftIcon />
            </a>
          </li>
          {pagesList.map((p) => (
            <li key={p}>
              <a
                href="#"
                className={p === currentPage ? "current" : ""}
                onClick={(e) => hdlPageChange(e, p)}
              >
                {p + 1}
              </a>
            </li>
          ))}
          <li>
            <a
              href="#"
              className="arrow"
              onClick={(e) =>
                hdlPageChange(
                  e,
                  Math.min(pagesList.length - 1, currentPage + 1)
                )
              }
            >
              <ChevronRightIcon />
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Pagination;
