import BackgroundPicture from "../../assets/images/makeus_background.png";
//import YallaFlatIcon from "../../assets/images/yalla-flat.png";
import AppButton from "../../components/atoms/app-button";
import Container from "../../components/atoms/container";
import TalkToUs from "../../components/atoms/talk-to-us";
import MakeusUnique from "../../components/molecules/makeus-unique";
import Solutions from "../../components/molecules/solutions";
//import texto from "../../assets/images/texto.png";
//import banner from "../../assets/images/banner.png";

import "./styles.scss";

interface Props {}

const HomePage: React.FC<Props> = () => {
  return (
    <section className="home-page">
    <div className="background"></div>
    <div className="header-player">
      <Container>
        
      <div className="iframe-container">
        <iframe
          scrolling="no"
          style={{
            top: "0",
            left: "0",
          position: "absolute",
          // top: 0,
          // left: 0,
           width: "100%",
            //maxWidth: "1920px",
           // margin: "0 auto",
            height: "100%",
            border: "0 none",
            background: "b#004A9E",
            transform: "scale(1)",
            transformOrigin: "top left",
            overflow: "hidden",
          }}
          src="https://reserva.easycarros.com/index.asp?COD_SISTEMA=1056"
        />
       {/*<img src={banner} alt="" style={{display: 'flex', objectFit: 'cover', zIndex: '-2'  }} />*/} 
         
        </div>
        
      </Container>
    </div>
    <Container>
      <Solutions title="Mobilidade é isso" linked>
        Soluções para todo tipo de necessidade
      </Solutions>
    </Container>
     
      <div style={{ paddingTop: '-50px' }}>
      <Container>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AppButton link="/about" label="Vamos juntos" />
        </div>
      </Container>
    </div>
   
      <div className="content">
      
        <Container>
          <MakeusUnique title="O que nos torna único" />
          <div className="talk-us">
            <TalkToUs
              link="https://api.whatsapp.com/send?phone=555511918489650"
              children="Quer saber mais?"
              title="Fale com a gente!"
            />
          </div>
        </Container>
        <img src={BackgroundPicture} className="lines" alt="lines" />
      </div>
    </section>
  );
};

export default HomePage;
