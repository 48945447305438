import React, { useMemo, useState } from "react";
import { Link, useLocation} from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import Container from "../../atoms/container";
import VectorIcon from "../../atoms/icons/vector";
import YallaIcon from "../../atoms/icons/YallaIcon";
import HeaderLogo from "../../../assets/images/yalla-logo-header.png";

import "./styles.scss";

type Link = {
  name: string;
  url: string;
};

interface Props {
  className?: string;
  links: Link[];
}

const SiteHeader: React.FC<Props> = ({ className, links }) => {
  const location = useLocation();
  const { theme } = useAppContext();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classNameValue = useMemo(() => {
    const classValues = ["app-site-header", theme];
    if (className) classValues.push(className);

    return classValues.join(" ");
  }, [className, theme]);

  return (
    <header className={classNameValue}>
      <Container>
        <Link
          className="desktopIcon"
          to="/"
          onClick={() => window.scrollTo(0, 0)}
        >
            <a href="#">
            <img src={HeaderLogo} alt="" />
          </a>
        </Link>
        <a
          className="mobileIcon"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
            setIsMenuOpen(!isMenuOpen);
          }}
        >
           <YallaIcon />
          <VectorIcon />
        </a>
        <nav className={isMenuOpen ? "open" : ""}>
          {links.map(({ name, url }) => (
            <li key={name}>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsMenuOpen(false);
                }}
                className={url === location.pathname ? "active" : ""}
                to={url}
              >
                {name}
              </Link>
            </li>
          ))}
        </nav>
      </Container>
      <div className={`floating-nav ${isMenuOpen ? "open" : ""}`}>
        <div className="content">
          <div className="header">
            <a
              href="#"
              className="inner-menu"
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
            >
              <YallaIcon />
              <VectorIcon />
            </a>
          </div>
          <div>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              to="/"
            >
              Yalla
            </Link>
          </div>
          <div>
            <p>Aluguel e carro por assinatura</p>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              to="/yalla/rent"
            >
              Yalla Rent
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              to="/yalla/green"
            >
              Yalla Green
            </Link>
          </div>
          <div>
            <p>Aluguel motoristas de app</p>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              to="/yalla/trust"
            >
              Yalla Trust
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              to="/yalla/green"
            >
              Yalla Green
            </Link>
          </div>
          <div>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              className="link"
              to="/yalla/my"
            >
              Venda de seminovos
            </Link>
          </div>
          <div>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setIsMenuOpen(false);
              }}
              className="link"
              to="/about"
            >
              Sobre a Yalla
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
