import React, { useMemo } from "react";
import AppButton from "../app-button";
import TitleSubtitle from "../title-subtitle";
import "./styles.scss";

interface Props {
  className?: string;
  link: string;
  linkText?: string;
}

const Interestedin: React.FC<Props> = ({
  className,
  link,
  linkText = "Cadastre-se",
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-interestedin"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  return (
    <div className={classNameValue}>
      <TitleSubtitle
        title={
          <>
            Tem interesse? <br />
            veja o que deve fazer
          </>
        }
      >
        É mais simples que fazer baliza. Olha só:
      </TitleSubtitle>
      <div className="steps">
        <div>
          <h2>1</h2>
          <p>Primeiro você envia seus documentos para cadastro.</p>
        </div>
        <div>
          <h2>2</h2>
          <p>
            Nossa equipe de atendimento fará uma análise e enviará uma proposta
            de acordo com o seu perfil.
          </p>
        </div>
        <div>
          <h2>3</h2>
          <div>
            <p>
              Depois de aprovada, seu veículo estará disponível para uso em 24
              horas.
            </p>
            <AppButton link={link} label={linkText} theme="white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interestedin;
