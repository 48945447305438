import { useMemo } from "react";
import "./styles.scss";

export type AdvancedListItemProps = {
  text: string;
  className?: string;
  icon: React.ReactNode;
};

const AdvancedListItem: React.FC<AdvancedListItemProps> = ({
  text,
  icon,
  className,
}) => {
  const classNameValue = useMemo(() => {
    const classNames = ["advanced-list-item"];
    if (!!className) {
      classNames.push(className);
    }
    return classNames.join(" ");
  }, [className]);

  return (
    <li className={classNameValue}>
      <span>{icon}</span>
      {text}
    </li>
  );
};

export default AdvancedListItem;
