import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import * as yup from "yup";
import AppButton from "../../atoms/app-button";
import FormGroup from "../../atoms/form-group";
import Input from "../../atoms/input";
import UploadField from "../../atoms/upload-field";

import CarIcon from "../../../assets/images/car_file.png";
import HouseIcon from "../../../assets/images/house_file.png";

import ContactApi from "../../../services/contact";
import "./styles.scss";

interface Props {
  className?: string;
  list: any[];
  interest?: string;
}

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

const Register: React.FC<Props> = ({ className, list, interest }) => {
  const [isLoadingCepData, setLoadingCepData] = useState<boolean>(false);
  const [sucess, setSucess] = useState<boolean>(false);

  const classNameValue = useMemo(() => {
    const classValues = ["app-register"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  const handleCepBlur = async (cep: string) => {
    setLoadingCepData(true);
    const response: any = await fetch(
      `https://viacep.com.br/ws/${cep.replace(/[.-]/gi, "")}/json`
    );
    const data = await response.json();

    formik.setValues({
      ...formik.values,
      evenue: data.bairro,
      address: data.logradouro,
      state: data.uf,
      city: data.localidade,
    });
    setLoadingCepData(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      vehicle: "",
      city: "",
      state: "",
      evenue: "",
      complement: "",
      addressData: "",
      address: "",
      cep: "",
      birthDay: "",
      nationality: "",
      gender: "",
      rg: "",
      cpf: "",
      contactType: "",
      residenceDocument: "",
      driverLicenceDocument: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("O campo é obrigatório."),
      phone: yup.string().required("O campo é obrigatório."),
      vehicle: yup.string().required("O campo é obrigatório."),
      city: yup.string().required("O campo é obrigatório."),
      state: yup.string().required("O campo é obrigatório."),
      evenue: yup.string().required("O campo é obrigatório."),
      addressData: yup.string().required("O campo é obrigatório."),
      address: yup.string().required("O campo é obrigatório."),
      cep: yup.string().required("O campo é obrigatório."),
      birthDay: yup.string().required("O campo é obrigatório."),
      nationality: yup.string().required("O campo é obrigatório."),
      gender: yup.string().required("O campo é obrigatório."),
      rg: yup.string().required("O campo é obrigatório."),
      cpf: yup.string().required("O campo é obrigatório."),
      contactType: yup.string().required("O campo é obrigatório."),
      residenceDocument: yup.string(),
      driverLicenceDocument: yup.string(),
      email: yup
        .string()
        .email("E-mail inválido.")
        .required("O campo é obrigatório."),
    }),
    onSubmit: async (values) => {
      console.log("vai");
      try {
        await ContactApi.send(values);
        setSucess(true);
        setTimeout(() => {
          setSucess(false);
        }, 3000);
        formik.resetForm();
      } catch (e) {
        alert("Erro ao enviar registro");
      }
    },
  });

  return (
    <div className={classNameValue}>
      <div className={`feedback ${sucess ? "show" : ""}`}>
        Enviado com sucesso!
      </div>
      <div>
        <h3>Faça seu cadastro agora mesmo. É rapidinho.</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <h4>Dados Pessoais</h4>
        <FormGroup>
          <Input
            full
            label="Nome Completo"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            errorLabel={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
          />
        </FormGroup>
        <FormGroup justifyContent="space-between">
          <Input
            mask="999.999.999-99"
            flex="1"
            label="CPF"
            name="cpf"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cpf}
            errorLabel={
              formik.touched.cpf && formik.errors.cpf ? formik.errors.cpf : ""
            }
          />
          <Input
            flex="1"
            name="rg"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.rg}
            errorLabel={
              formik.touched.rg && formik.errors.rg ? formik.errors.rg : ""
            }
            label="RG"
          />
          <div>
            <label style={{ display: "block", marginBottom: 5 }} htmlFor="">
              Genero
            </label>{" "}
            <select
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.vehicle && formik.errors.vehicle ? "error" : ""
              }
              value={formik.values.gender}
            >
              <option value="">Selecione</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
            </select>
            {formik.touched.gender && formik.errors.gender && (
              <small className="error">{formik.errors.gender}</small>
            )}
          </div>
          {/* <Input flex="1" label="Gênero" /> */}
        </FormGroup>
        <FormGroup justifyContent="space-between">
          <Input
            full
            label="Nacionalidade"
            name="nationality"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nationality}
            errorLabel={
              formik.touched.nationality && formik.errors.nationality
                ? formik.errors.nationality
                : ""
            }
          />
          <Input
            full
            mask="99/99/9999"
            name="birthDay"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.birthDay}
            errorLabel={
              formik.touched.birthDay && formik.errors.birthDay
                ? formik.errors.birthDay
                : ""
            }
            label="Data de nascimento"
          />
        </FormGroup>
        <h4>Endereço</h4>
        <FormGroup>
          <Input
            label="CEP"
            mask="99.999-999"
            name="cep"
            onChange={formik.handleChange}
            value={formik.values.cep}
            errorLabel={
              formik.touched.cep && formik.errors.cep ? formik.errors.cep : ""
            }
            onBlur={(e) => {
              formik.handleBlur(e);
              handleCepBlur(e.target.value);
            }}
          />
          <Input
            name="address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            errorLabel={
              formik.touched.address && formik.errors.address
                ? formik.errors.address
                : ""
            }
            flex="1"
            label="Endereço"
          />
        </FormGroup>
        <FormGroup>
          <Input
            name="addressData"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.addressData}
            errorLabel={
              formik.touched.addressData && formik.errors.addressData
                ? formik.errors.addressData
                : ""
            }
            type="tel"
            flex="1"
            label="Número"
          />
          <Input
            name="complement"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.complement}
            errorLabel={
              formik.touched.complement && formik.errors.complement
                ? formik.errors.complement
                : ""
            }
            flex="2"
            disabled={isLoadingCepData}
            placeholder={isLoadingCepData ? "carregando..." : ""}
            label="Complemento"
          />
          <Input
            name="evenue"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.evenue}
            errorLabel={
              formik.touched.evenue && formik.errors.evenue
                ? formik.errors.evenue
                : ""
            }
            flex="2"
            disabled={isLoadingCepData}
            placeholder={isLoadingCepData ? "carregando..." : ""}
            label="Bairro"
          />
        </FormGroup>
        <FormGroup>
          <Input
            width={50}
            label="UF"
            name="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            errorLabel={
              formik.touched.state && formik.errors.state
                ? formik.errors.state
                : ""
            }
            disabled={isLoadingCepData}
            placeholder={isLoadingCepData ? "carregando..." : ""}
          />
          <Input
            label="Cidade"
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            errorLabel={
              formik.touched.city && formik.errors.city
                ? formik.errors.city
                : ""
            }
            disabled={isLoadingCepData}
            placeholder={isLoadingCepData ? "carregando..." : ""}
          />
        </FormGroup>
        <h4>Contato</h4>
        <FormGroup justifyContent="space-between">
          <Input
            flex="1"
            label="E-mail"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errorLabel={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <Input
            flex="1"
            mask="(99) 99999-9999"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            errorLabel={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : ""
            }
            label="Telefone"
          />
        </FormGroup>
        <h4>Estamos quase lá!</h4>
        <FormGroup>
          <UploadField
            flex="1"
            label="Anexar comprovante de residência"
            fileTypes={["PDF"]}
            icon={<img alt="" src={HouseIcon} />}
            name="residenceDocument"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.residenceDocument}
            errorLabel={
              formik.touched.residenceDocument &&
              formik.errors.residenceDocument
                ? formik.errors.residenceDocument
                : ""
            }
          />
          <UploadField
            flex="1"
            label="Anexar cópia da sua CNH"
            name="driverLicenceDocument"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.driverLicenceDocument}
            icon={<img alt="" src={CarIcon} />}
            fileTypes={["PDF"]}
            errorLabel={
              formik.touched.driverLicenceDocument &&
              formik.errors.driverLicenceDocument
                ? formik.errors.driverLicenceDocument
                : ""
            }
          />
        </FormGroup>
        <FormGroup>
          <div style={{ width: "100%", display: "block" }}>
            <label style={{ display: "block", marginBottom: 5 }} htmlFor="">
              Veículo de interesse
            </label>
            <select
              name="vehicle"
              onChange={formik.handleChange}
              className={
                formik.touched.vehicle && formik.errors.vehicle ? "error" : ""
              }
              onBlur={formik.handleBlur}
              value={formik.values.vehicle}
              style={{ width: "100%", display: "block" }}
            >
              <option value="">Selecione</option>
              {list.length &&
                list.map((l) => (
                  <option
                    key={l.id}
                    value={l.model.name}
                    style={{ textTransform: "capitalize" }}
                  >
                    {capitalizeFirstLetter(l.factory.name)} -{" "}
                    {capitalizeFirstLetter(l.model.name)}
                  </option>
                ))}
            </select>
            {formik.touched.vehicle && formik.errors.vehicle && (
              <>
                <small className="error">{formik.errors.vehicle}</small>
                <br />
              </>
            )}
          </div>
        </FormGroup>
        <FormGroup>
          <div>
            <label style={{ display: "block", marginBottom: 5 }} htmlFor="">
              Como prefere que a gente entre em contato
            </label>
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              <label>
                <input
                  {...formik.getFieldProps("contactType")}
                  type="radio"
                  name="contactType"
                  value="WhatsApp"
                />
                &nbsp; WhatsApp
              </label>
              <label>
                <input
                  {...formik.getFieldProps("contactType")}
                  type="radio"
                  name="contactType"
                  value="Telefone"
                />
                &nbsp; Telefone
              </label>
              <label>
                <input
                  {...formik.getFieldProps("contactType")}
                  type="radio"
                  name="contactType"
                  value="E-mail"
                />
                &nbsp; E-mail
              </label>
            </div>
            {formik.touched.contactType && formik.errors.contactType && (
              <>
                <br />
                <small className="error">{formik.errors.contactType}</small>
              </>
            )}
          </div>
        </FormGroup>
        <AppButton label="Enviar" type="submit" fullSize />
      </form>
    </div>
  );
};

export default Register;
