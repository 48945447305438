import AppButton from "../../../../components/atoms/app-button";
import Container from "../../../../components/atoms/container";
import DirectionsCarIcon from "../../../../components/atoms/icons/directions-car";
import PlaceIcon from "../../../../components/atoms/icons/place";
import SecurityIcon from "../../../../components/atoms/icons/security";
import YallaIcon from "../../../../components/atoms/icons/YallaIcon";
import Inlinefleet from "../../../../components/atoms/inlinefleet";
import Yallagroup from "../../../../components/atoms/yallagroup";
import AdvancedList from "../../../../components/molecules/advanced-list";
import BookCar from "../../../../components/organisms/book-car";

import "./styles.scss";

interface Props {}

const OverviewPage: React.FC<Props> = () => {
  return (
    <section className="rent-overview">
      <Container>
        <BookCar />
        <Inlinefleet
          title="Nossa frota"
          businessType="rent"
          seeMoreLink="/yalla/rent/fleet"
          noFleetLink
        >
          Estes são os carros esperando por você.
        </Inlinefleet>
        <div className="why-area">
          <div>
            <h3>
              VEJA QUAIS SÃO AS <span>VANTAGENS</span>
            </h3>
          </div>

          <div>
            <AdvancedList
              listItems={[
                {
                  icon: <YallaIcon />,
                  text: "Viva a experiência Yalla",
                },
                {
                  icon: <DirectionsCarIcon />,
                  text: "Enorme variedade de veículos para você escolher",
                },
                {
                  icon: <SecurityIcon />,
                  text: "Carros protegidos para você não ter preocupações",
                },
                {
                  icon: <PlaceIcon />,
                  text: "Devolução em qualquer unidade",
                },
              ]}
            />
          </div>
        </div>
        <div className="our-contacts">
          <h3>
            Quer saber <br /> como alugar?
          </h3>
          <div>
            <div>
              <h4>Aluguel de carros</h4>
              <p>
                Você pode efetuar uma reserva através do nosso site, nas nossas
                lojas ou com nossa equipe de atendimento.
              </p>
              <AppButton
                externalLink="https://api.whatsapp.com/send?phone=5511930576936"
                theme="white"
                label="Reserve já"
              />
            </div>
            <div>
              <h4>Assinatura de carros</h4>
              <p>
                Preencha nossa ficha de cadastro ou entre em contato com a nossa
                equipe de atendimento e encontre o melhor veículo para a sua
                necessidade.
              </p>
              <AppButton
                link="/yalla/rent/register"
                theme="white"
                label="Cadastre-se agora"
              />
            </div>
            <div>
              <h4>Aluguel para empresas</h4>
              <p>
                Entre em contato com nossa equipe de atendimento para encontrar
                a melhor solução para sua empresa.
              </p>
              <AppButton
                externalLink="https://api.whatsapp.com/send?phone=5511930576936"
                theme="white"
                label="Fale com a gente"
              />
            </div>
          </div>
        </div>
        <Yallagroup product="Yalla Rent" />
      </Container>
    </section>
  );
};

export default OverviewPage;
