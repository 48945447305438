import { useEffect, useMemo } from "react";
import { Routes, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "./App.scss";
import SiteFooter from "./components/organisms/site-footer";
import SiteHeader from "./components/organisms/site-header";
import { AppProvider, useAppContext } from "./context/AppContext";
import Theme from "./models/theme";
import AboutPage from "./pages/about-page";
import HomePage from "./pages/home-page";
import MyYallaPage from "./pages/my-yalla-page";
import NotFoundPage from "./pages/not-found-page";
import PolicyPage from "./pages/policy";
import YallaGreenPage from "./pages/yalla-green-page";

import YallaRentPage from "./pages/yalla-rent-page";
import YallaTrustPage from "./pages/yalla-trust-page";

const App = () => {
  const location = useLocation();
  const { setTheme, theme } = useAppContext();
  useEffect(() => {
    if (location.pathname.includes("yalla/rent")) setTheme(Theme.rent);
    else if (location.pathname.includes("yalla/green")) setTheme(Theme.green);
    else if (location.pathname.includes("yalla/my")) setTheme(Theme.my);
    else if (location.pathname.includes("yalla/trust")) setTheme(Theme.trust);
    else setTheme(Theme.main);
  }, [location.pathname, setTheme]);

  const mainTheme = useMemo(() => {
    return ["App", theme].join(" ");
  }, [theme]);

  return (
    <main className={mainTheme}>
      <SiteHeader
        links={[
          {
            name: "Aluguel e carro por assinatura",
            url: "/yalla/rent",
          },
         {
            name: "Para motoristas de app",
            url: "/yalla/trust",
          },
          {
            name: "Venda de seminovos",
            url: "/yalla/my",
          },
          {
            name: "Sobre a Yalla",
            url: "/about",
          },
        ]}
      />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/yalla/trust/*" element={<YallaTrustPage />} />
        <Route path="/yalla/green/*" element={<YallaGreenPage />} />
        <Route path="/yalla/my/*" element={<MyYallaPage />} />
        <Route path="/yalla/rent/*" element={<YallaRentPage />} />
        <Route path="/policy/*" element={<PolicyPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>

      <SiteFooter />
    </main>
  );
};

const Wrapper = () => (
  <AppProvider>
    <App />
  </AppProvider>
);

export default Wrapper;
