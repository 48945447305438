import React, { useMemo } from "react";
import HorizontalLine01 from "../../../assets/images/grafismos_01.svg";
import HorizontalLine02 from "../../../assets/images/grafismos_02.svg";
import HorizontalLine03 from "../../../assets/images/grafismos_03.svg";
import HorizontalLine04 from "../../../assets/images/grafismos_04.svg";
import HorizontalLine11 from "../../../assets/images/grafismos_11.svg";
import "./styles.scss";

interface Props {
  className?: string;
  bgNumber?: "01" | "02" | "03" | "04" | "11";
}

const Divisor: React.FC<Props> = ({ className, bgNumber }) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-divisor"];
    if (className) classValues.push(className);
    return classValues.join(" ");
  }, [className]);

  const HorizontalLine = useMemo(() => {
    if (bgNumber === "01") return HorizontalLine01;
    if (bgNumber === "02") return HorizontalLine02;
    if (bgNumber === "03") return HorizontalLine03;
    if (bgNumber === "04") return HorizontalLine04;
    if (bgNumber === "11") return HorizontalLine11;

    return HorizontalLine03;
  }, [bgNumber]);

  return (
    <div className={classNameValue}>
      <img src={HorizontalLine} alt="" />
    </div>
  );
};

export default Divisor;
