import { Route, Routes } from "react-router-dom";
import About from "./inner-pages/about";
import Fleet from "./inner-pages/fleet";

import Overview from "./inner-pages/overview";
import Register from "./inner-pages/register";

import YallaMyIcon from "../../assets/images/solutions/my_yalla.png";

import Container from "../../components/atoms/container";
import SolutionHeader from "../../components/molecules/solution-header";
import FleetItem from "./inner-pages/fleet-item";
import "./styles.scss";

const MyYallaPage = () => {
  return (
    <section className="my-yalla-page">
      <Container>
        <SolutionHeader
          iconPath={YallaMyIcon}
          segmentPath="my"
          title="My Yalla"
        />
      </Container>

      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/fleet/:id" element={<FleetItem />} />
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </section>
  );
};

export default MyYallaPage;
