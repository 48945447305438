import Container from "../../../../components/atoms/container";
import AppFleet from "../../../../components/organisms/app-fleet";
import WhatsappIcon from "../../../../assets/images/WhatsApp.png";

interface Props {}

const FleetPage: React.FC<Props> = () => {
  return (
    <section>
      <Container>
        <AppFleet
          externalLink="https://api.whatsapp.com/send?phone=555511918489650"
          icon={WhatsappIcon}
          businessType="my"
          seeMoreLink="/yalla/my/fleet"
          theme="punch"
        />
      </Container>
    </section>
  );
};

export default FleetPage;
