import { IconProps } from ".";

const BoltIcon: React.FC<IconProps> = ({ className, size = 40, color }) => {
  const proportion = size / 40;
  const width = 40 * proportion;
  const height = 40 * proportion;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3342 35H16.6675L18.3342 23.3333H12.5008C11.5342 23.3333 11.5508 22.8 11.8675 22.2333C12.1842 21.6667 11.9508 22.1 11.9842 22.0333C14.1342 18.2333 17.3675 12.5667 21.6675 5H23.3342L21.6675 16.6667H27.5008C28.3175 16.6667 28.4342 17.2167 28.2842 17.5167L28.1675 17.7667C21.6008 29.25 18.3342 35 18.3342 35Z"
        fill={color}
      />
    </svg>
  );
};

export default BoltIcon;
