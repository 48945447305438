import Container from "../../components/atoms/container";
import Video from "../../components/atoms/video";
import Solutions2 from "../../components/molecules/solutions2";
import Location from "../../components/organisms/location";

import YallaIcon from "../../assets/images/solutions/yalla_b.png";

import Divisor from "../../components/atoms/divisor";
import "./styles.scss";

interface Props {}

const HomePage: React.FC<Props> = () => (
  <section className="about-page">
    <div className="header-player">
      <div className="background"></div>
      <Container>
        <img src={YallaIcon} alt="" />
      </Container>
      <Container className="video-header">
        <div>
          <h2 className="normal">
            Estamos construindo <span>uma nova</span>
            <span> estrada</span>
          </h2>
        </div>
        <div>
          <Video
            srcVideo="https://youtu.be/w1JdAJjUcfc"
            thumbnailVideo={`https://img.youtube.com/vi/w1JdAJjUcfc/hqdefault.jpg`}
          />
          <p>
            Transformando a vida das pessoas e respeitando o planeta, é possível
            evoluir o mercado. E nos sentamos no banco do motorista para dirigir
            essa evolução.
          </p>
        </div>
      </Container>
    </div>
    <Divisor />
    <Container>
      <Solutions2
        title="Nossas Unidades de negócio geram valores econômicos e socioambiental"
        noDescription
      ></Solutions2>
    </Container>
    <div className="about-content">
      <Container>
        <h3>MISSÃO</h3>
        <h5>
          Contribuir com a evolução do mercado de mobilidade, transformando a
          vida das pessoas e respeitando o planeta.
        </h5>
        <div className="visao">
          <div>
            <h3>
              <span>VISÃO</span>
            </h3>
          </div>
          <div>
            <p>
              Até 2030, intencionamos contribuir com a evolução do mercado de
              mobilidade, atuando como uma marca rentável e que gera impacto
              socioambiental.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>
              <span>VALORES</span>
            </h3>
          </div>
          <div>
            <p>
              Honestidade
              <br />
              Confiança
              <br />
              Esperança
              <br />
              Coerência
              <br />
              Resiliência
            </p>
          </div>
        </div>
      </Container>
    </div>
    <Location />
  </section>
);

export default HomePage;
