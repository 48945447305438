import React, { useMemo } from "react";
import "./styles.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  justifyContent?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
  spaced?: boolean;
}

const FormGroup: React.FC<Props> = ({
  children,
  className,
  justifyContent,
  alignItems,
  spaced = true,
}) => {
  const classNameValue = useMemo(() => {
    const classValues = ["app-form-group"];
    if (className) classValues.push(className);
    if (spaced) classValues.push("spaced");
    return classValues.join(" ");
  }, [className, spaced]);

  return (
    <div className={classNameValue} style={{ justifyContent, alignItems }}>
      {children}
    </div>
  );
};

export default FormGroup;
