import React, { useEffect, useMemo, useRef, useState } from "react";
import SolutionCard from "../../atoms/solution-card";

import { SolutionCardProps } from "../../atoms/solution-card/SolutionCard";
import "./styles.scss";

interface Props {
  children?: React.ReactNode;
  title?: string;
  linked?: boolean;
  noDescription?: boolean;
  className?: string;
}

const solutions: SolutionCardProps[] = [
  {
    linkPath: "yalla/rent",
    borderColor: "indian-khaki",
    borderPosition: "bottom-right",
    children:
      "Locação de veículos para o seu dia a dia ou para uma viagem.",
    photoSrc: require("../../../assets/images/solutions/yalla_rent.png"),
    photoSrcB: require("../../../assets/images/solutions/yalla_rent_b.png"),
  },
  {
    borderPosition: "top-right",
    borderColor: "butter-cup",
    linkPath: "yalla/trust",
    children:
      "Locação de veículos para aplicativo e mobilidade urbana.",
    photoSrc: require("../../../assets/images/solutions/yalla_trust.png"),
    photoSrcB: require("../../../assets/images/solutions/yalla_trust_b.png"),
  },

  {
    borderColor: "punch",
    linkPath: "yalla/my",
    borderPosition: "top-right",
    children:
      "Venda de veículos seminovos para você que quer um carro seu na garagem.",
    photoSrc: require("../../../assets/images/solutions/my_yalla.png"),
    photoSrcB: require("../../../assets/images/solutions/my_yalla_b.png"),
  },
  {
    borderColor: "turquoise",
    linkPath: "yalla/green",
    borderPosition: "bottom-right",
    children: "Locação de veículos híbridos e elétricos de alto padrão.",
    photoSrc: require("../../../assets/images/solutions/yalla_green.png"),
    photoSrcB: require("../../../assets/images/solutions/yalla_green_b.png"),
  },
  
];

const Solutions: React.FC<Props> = ({
  children,
  className,
  title,
  linked,
  noDescription,
}) => {
  const cardsRef = useRef<HTMLDivElement>(null);
  const [psx, setPsx] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (cardsRef.current?.offsetTop) {
      const offsetTop = cardsRef.current?.offsetTop - 100;
      console.log(offsetTop, position);
      if (position > offsetTop && window.innerWidth < 1024) {
        const currentBoxPos = Math.floor((position - offsetTop) / 300);
        setPsx(currentBoxPos);
      } else {
        setPsx(-1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classNameValue = useMemo(() => {
    const classValues = ["app-solutions"];
    if (className) classValues.push(className);
    if (linked) classValues.push("link");
    return classValues.join(" ");
  }, [className, linked]);

  return (
    <div className={classNameValue}>
      <h2>{title}</h2>
      <p>{children}</p>
      <br />

      <div ref={cardsRef}>
        {solutions.map((solution, index) => (
          <SolutionCard
            linked={linked}
            key={solution.linkPath}
            noDescription={noDescription}
            {...solution}
            className={psx === index ? "active" : "no-active"}
            style={{
              width: "100%",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Solutions;
